import { ContentsEdit } from '@teamsparta/design-system';
import { When } from '@teamsparta/react';
import { Button } from '@teamsparta/stack-button';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';

import { useIsDesktop } from '@/hooks';
import { breakpoints } from '@/styles/themes';

interface Props {
  onEdit?: () => void;
}

export function EditButton({ onEdit }: Props) {
  const isDesktop = useIsDesktop({ breakpoint: breakpoints.largeTablet });

  return (
    <When
      condition={isDesktop}
      fallback={
        <Flex.Row
          as='button'
          padding='13px 0'
          gap={8}
          onClick={onEdit}
          aria-label='수정하기'
          align='center'
          fullWidth
        >
          <ContentsEdit size={16} color={vars.neutral[5]} />
          <Text as='button' font='bodyM' color={vars.neutral[0]}>
            수정하기
          </Text>
        </Flex.Row>
      }
    >
      <Button
        leftAddon={<ContentsEdit size={16} color={vars.neutral[5]} />}
        fullWidth
        size='xs'
        colorScheme='tertiary'
        onClick={onEdit}
      >
        수정하기
      </Button>
    </When>
  );
}
