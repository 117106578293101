import { When } from '@teamsparta/react';

import { useLoggedIn } from '@/hooks';

import { LoginButton, Profile } from './components';

export function User() {
  const { loggedIn, loading } = useLoggedIn();

  return (
    <>
      {!loading && (
        <When condition={loggedIn} fallback={<LoginButton />}>
          <Profile />
        </When>
      )}
    </>
  );
}
