import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { captionM } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';

export const ButtonWrapper = styled.div`
  min-width: 26px;
  position: relative;
  display: inline-block;
  padding: 0;
`;

export const TooltipBox = styled.span<{ opened: boolean }>`
  visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
  width: 280px;
  background-color: ${vars.neutral[80]};
  color: #fff;
  padding: 16px 16px;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  top: 42px;
  right: -9.5px;
  margin-left: -60px;
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  border: 1px solid ${vars.neutral[70]};
  display: flex;
  flex-direction: column;
  gap: 12px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 10px;
    border-width: 10px;
    border-style: solid;
  }

  &::before {
    border-color: transparent transparent ${vars.neutral[70]} transparent;
  }

  &::after {
    border-color: transparent transparent ${vars.neutral[80]} transparent;
    bottom: calc(100% - 2px);
  }
`;
export const LinkButtonStyle = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  b {
    ${captionM};
    color: ${vars.neutral[0]};
  }
`;

export const LinkButtonList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const GnbLogoutButton = styled.button`
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  ${captionM};
  color: ${vars.neutral[40]};
  cursor: pointer;
`;

export const Blank = styled.div`
  width: 32px;
  background-color: transparent;
`;
