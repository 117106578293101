import styled from '@emotion/styled';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { vars } from '@teamsparta/stack-tokens';

export const Content = styled(RadixTooltip.Content)`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${vars.neutral[80]};
  z-index: 3;
`;
