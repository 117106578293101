import { useAtom } from 'jotai';
import Image from 'next/legacy/image';

import { useIntersectionObserver, useIsDesktop } from '@/hooks';
import { hasReachedFooterAtom } from '@/store/landing';

import * as S from './style';

export default function Footer() {
  const isDesktop = useIsDesktop();

  const [, setHasReachedFooter] = useAtom(hasReachedFooterAtom);

  const separator = isDesktop ? ' | ' : <br />;

  //푸터에 닿으면 플로팅cta를 사라지기 하기 위해 Intersection 사용
  const ref = useIntersectionObserver({
    onIntersect: () => {
      setHasReachedFooter(true);
    },
    onIntersectEnd: () => {
      setHasReachedFooter(false);
    },
  });

  return (
    <S.Wrapper id='footer' ref={ref}>
      <S.Header>
        <S.SiteMapWrapper>
          <h4>SiteMap</h4>
          <S.SiteMap>
            <a
              href='https://spartacodingclub.kr/'
              target='_blank'
              rel='noreferrer'
            >
              스파르타코딩클럽
            </a>
            <a
              href='https://port99.spartacodingclub.kr/'
              target='_blank'
              rel='noreferrer'
            >
              스파르타 커리어
            </a>
          </S.SiteMap>
        </S.SiteMapWrapper>
        <S.ApplyWrapper>
          <h4>Apply</h4>
          <S.ApplyItem>
            <a
              href='https://spartacodingclub.career.greetinghr.com/'
              target='_blank'
              rel='noreferrer'
            >
              튜터 지원
            </a>
            <a
              href='https://intellipick.spartacodingclub.kr/company-introduce'
              target='_blank'
              rel='noreferrer'
            >
              협력사 지원
            </a>
          </S.ApplyItem>
        </S.ApplyWrapper>
        <S.ButtonWrapper>
          <S.ImageButton
            onClick={() =>
              window.open('https://hanghae99.spartacodingclub.kr/story')
            }
          >
            <Image
              src='/images/footer/naver.png'
              width={30}
              height={30}
              alt='blog'
            />
          </S.ImageButton>
          <S.ImageButton
            onClick={() => window.open('https://www.instagram.com/hanghae99/')}
          >
            <Image
              src='/images/footer/instagram.png'
              width={30}
              height={30}
              alt='instagram'
            />
          </S.ImageButton>
          <S.ImageButton
            onClick={() =>
              window.open(
                'https://www.youtube.com/channel/UCi2LvS1cmM8mCZwBC1DiOPg/featured',
              )
            }
          >
            <Image
              src='/images/footer/youtube.png'
              width={30}
              height={30}
              alt='youtube'
            />
          </S.ImageButton>
        </S.ButtonWrapper>
      </S.Header>
      <S.Terms>
        <a
          href='https://teamsparta.notion.site/7b1dc644460946f08bab08b794de685f'
          target='_blank'
          rel='noreferrer'
        >
          개인정보처리방침
        </a>
        <a
          href='https://teamsparta.notion.site/247d57da1322424d8e8c551df21a048e'
          target='_blank'
          rel='noreferrer'
        >
          서비스 이용 약관
        </a>
        <a
          href='https://teamsparta.notion.site/99-657fb575dd2c48809308275c2d88b66c'
          target='_blank'
          rel='noreferrer'
        >
          환불 규정
        </a>
        <a
          href='https://support.spartacodingclub.kr/faqs'
          target='_blank'
          rel='noreferrer'
        >
          고객센터
        </a>
        {/*<span>고객 문의</span>*/}
      </S.Terms>
      <S.CompanyInfo>
        <h4>팀스파르타(주) 사업자 정보</h4>
        <S.CompanyDetail>
          대표자: 이범규
          {separator}
          사업자 등록번호: 783-86-01715
          {separator}
          통신판매업 신고번호: 2020-서울강남-02300
          {separator}
          평생교육시설 신고번호: 제 661호
          <br />
          주소: 서울특별시 강남구 테헤란로44길 8 12층
          {separator}
          (아이콘역삼빌딩)
          {separator}
          이메일: contact@teamsparta.co
          {separator}
          전화: 1522-8016
          {separator}
          {/* 빠른상담 : 홈페이지 채팅문의버튼 */}
          <S.CopyrightInfo>
            Copyright ©2022 TEAMSPARTA. All rights reserved.
          </S.CopyrightInfo>
        </S.CompanyDetail>
      </S.CompanyInfo>
    </S.Wrapper>
  );
}
