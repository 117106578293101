import styled from '@emotion/styled';
import * as RadixPopover from '@radix-ui/react-popover';
import { vars } from '@teamsparta/stack-tokens';

export const PopoverContent = styled(RadixPopover.Content)`
  padding: 8px;
  border-radius: 8px;
  background-color: ${vars.neutral[80]};
  box-shadow: 0px 8px 24px 0px rgba(129, 137, 143, 0.12);
`;
