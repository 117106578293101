import { useSuspenseQuery } from '@tanstack/react-query';

import { useCustomLoadableRemoteConfig } from '@/hooks';
import { recentActiveOrientationQuery } from '@/hooks/react-query/common/orientation';
import type { PlusCourse, PlusCourseRoute } from '@/types';

const KEY = 'hh_orientation_date';

type OrientationBanner = Record<
  `plus-${PlusCourseRoute}`,
  {
    url: string;
    banner: {
      title: string;
      background: string;
      speechBubbleText: string;
      location: string;
      image: {
        mobile: {
          url: string;
          width: number;
          height: number;
        };
        desktop: {
          url: string;
          width: number;
          height: number;
        };
      };
    };
    modal: {
      title: string;
      description: string;
      coach: string;
      location: string;
      image: {
        mobile: {
          url: string;
          width: number;
          height: number;
        };
        desktop: {
          url: string;
          width: number;
          height: number;
        };
      };
    };
  }
>;

export function useOrientationBanner(courseName: PlusCourse) {
  const remoteConfig = useCustomLoadableRemoteConfig<OrientationBanner>(KEY);
  const { data: orientation } = useSuspenseQuery({
    ...recentActiveOrientationQuery(courseName),
    staleTime: Infinity,
  });

  return { remoteConfig, orientation };
}
