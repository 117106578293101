import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';

import { LoggingClick } from '@/components/common/LoggingClick';
import type { GNBLinkMenu } from '@/components/new-gnb/data';

import * as S from './style';

export function LinkMenu({ url = '', name, newTab = false }: GNBLinkMenu) {
  return (
    <LoggingClick logName='hh_gnb_click' data={{ button_text: name }}>
      <S.StyledLink
        href={url}
        target={newTab ? '_blank' : '_self'}
        rel={newTab ? 'noopener noreferrer' : undefined}
      >
        <Text as='span' font='bodyM' color={vars.text.secondary}>
          {name}
        </Text>
      </S.StyledLink>
    </LoggingClick>
  );
}
