import { isEmptyArray } from '@teamsparta/utils';
import Head from 'next/head';

interface CommonHeadProps {
  /**
   * 페이지 제목
   */
  title: string;
  /**
   * 페이지 설명
   */
  description: string;
  /**
   * 페이지 URL
   */
  url: string;
  /**
   * 페이지 작성자
   */
  author?: string;
  /**
   * 페이지 타입
   * @default 'website'
   * @example 'website' | 'article'
   */
  type?: 'website' | 'article';
  /**
   * 페이지 언어
   * @default 'ko_KR'
   * @example 'ko_KR' | 'en_US'
   */
  locale?: 'ko_KR' | 'en_US';
  /**
   * 페이지 키워드
   * @default []
   * @example ['항해99', '스파르타코딩클럽', '스파르타']
   */
  keywords?: string | string[];
  /**
   * 페이지 검색 엔진 크롤링 여부
   * @default 'index, follow'
   * @example 'index, follow' | 'noindex, nofollow'
   */
  robots?: 'index, follow' | 'noindex, nofollow';
  /**
   * 구글 검색 엔진 크롤링 여부
   * @default 'index, follow'
   * @example 'index, follow' | 'noindex, nofollow'
   */
  googlebot?: 'index, follow' | 'noindex, nofollow';
  /**
   * 네이버 검색 엔진 크롤링 여부
   * @default 'index, follow'
   * @example 'index, follow' | 'noindex, nofollow'
   */
  yeti?: 'index, follow' | 'noindex, nofollow';
  /**
   * 페이지 썸네일
   */
  thumbnail?: string;
  /**
   * 페이지 정규화 URL
   * @description 페이지 정규화 URL은 페이지의 원본 URL을 나타내는 것으로, 페이지의 중복 콘텐츠를 방지하는 데 사용됩니다.
   * @default ''
   */
  canonical?: string;
  /**
   * 페이지 아이콘
   * @default '/images/favicon/hanghae_favicon.ico'
   */
  favicon?: string;
}

export function Meta({
  title,
  description,
  url,
  author = '스파르타코딩클럽',
  type = 'website',
  locale = 'ko_KR',
  keywords = [],
  robots = 'index, follow',
  googlebot = 'index, follow',
  yeti = 'index, follow',
  thumbnail,
  canonical,
  favicon = '/images/favicon/hanghae_favicon.ico',
}: CommonHeadProps) {
  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='url' content={url} />
      <meta name='type' content={type} />
      <meta name='locale' content={locale} />
      <link rel='icon' href={favicon} type='image/x-icon' />
      <meta name='robots' content={robots} />
      <meta name='googlebot' content={googlebot} />
      <meta name='yeti' content={yeti} />
      <meta name='author' content={author} />
      {thumbnail && <meta name='thumbnail' content={thumbnail} />}
      {canonical && <link rel='canonical' href={canonical} />}
      {typeof keywords === 'string' ? (
        <meta name='keywords' content={keywords} />
      ) : (
        keywords &&
        !isEmptyArray(keywords) && (
          <meta name='keywords' content={keywords.join(', ')} />
        )
      )}
    </Head>
  );
}
