import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';
import { parseQueryStringToObject } from '@/lib/utils/parse';
import type { UserInfo } from '@/lib/utils/businessLogics/loginValidation';
import { env } from '@/lib/utils/env';

function useInitDatadog() {
  const RUM_CONFIG = {
    SESSION_SAMPLE_RATE: 100,
    SESSION_REPLAY_SAMPLE_RATE: 100,
  } as const;
  useEffect(() => {
    try {
      const appId = env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
      const clientToken = env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

      if (!appId || !clientToken) {
        return;
      }

      datadogRum.init({
        applicationId: appId,
        clientToken: clientToken,
        site: 'datadoghq.com',
        service: 'hanghae-frontend',
        version: '1.0.0',
        env: process.env.IS_PROD
          ? 'prod'
          : process.env.IS_TEST
          ? 'test'
          : 'local',
        sessionSampleRate: RUM_CONFIG.SESSION_SAMPLE_RATE,
        sessionReplaySampleRate: RUM_CONFIG.SESSION_REPLAY_SAMPLE_RATE,
        defaultPrivacyLevel: 'mask-user-input',

        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        beforeSend: (event) => {
          if (event.type === 'error') {
            // 에러 이벤트 발생 시 세션 리플레이 녹화 시작
            datadogRum.startSessionReplayRecording();
          }
          return true;
        },
      });
    } catch (error) {
      console.error('Datadog 초기화 실패:', error);
    }
  }, []);
}

function useInitDatadogUser() {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    const userInfoCookie = getCookie('userinfo');
    if (userInfoCookie) {
      setUserInfo(
        parseQueryStringToObject<UserInfo>(decodeURIComponent(userInfoCookie)),
      );
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      datadogRum.setUser({
        name: userInfo.name,
        email: userInfo.email,
        phone: userInfo.phone,
      });
    }
  }, [userInfo]);
}

export function useSetDataDog() {
  useInitDatadog();
  useInitDatadogUser();
}
