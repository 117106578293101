import styled from '@emotion/styled';
import { bodyB, captionM } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';

import { LARGE_TABLET } from '@/styles/themes';

export const PostContainer = styled.div`
  width: 100%;
  max-width: 820px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  ${LARGE_TABLET} {
    padding: 20px;
    max-width: 580px;
    gap: 16px;
    background: ${vars.neutral[95]};
  }
`;

export const PostTitle = styled.h3`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  ${bodyB};
  color: ${vars.neutral[10]};

  ${LARGE_TABLET} {
    ${bodyB};
    color: ${vars.neutral[10]};
  }
`;

export const PostContent = styled.p`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  ${captionM};
  color: ${vars.neutral[50]};

  ${LARGE_TABLET} {
    ${captionM};
    color: ${vars.neutral[50]};
  }
`;
