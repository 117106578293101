import * as Accordion from '@radix-ui/react-accordion';
import { useBoolean, When } from '@teamsparta/react';
import { ArrowChevronDown, ArrowChevronUp } from '@teamsparta/stack-icons';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { AnimatePresence, motion } from 'framer-motion';

import { LoggingClick } from '@/components/common/LoggingClick';
import type { GNBDropdownMenu } from '@/components/new-gnb/data';
import { debounce } from '@/components/new-gnb/logic';
import { SECOND } from '@/constants';

import { InnerAccordion } from './components';
import * as S from './style';

interface Props extends GNBDropdownMenu {}

const ANIMATION_DURATION = 0.12;
const DEBOUNCE_TIME = (ANIMATION_DURATION + 0.03) * SECOND;

export function AccordionMenu({ name, items }: Props) {
  const { value: open, toggle } = useBoolean(name === '교육');
  const debouncedToggle = debounce(toggle, DEBOUNCE_TIME);

  return (
    <Accordion.Root
      type='single'
      style={{ width: '100%' }}
      collapsible
      value={open ? name : ''}
      onValueChange={debouncedToggle}
    >
      <Accordion.Item value={name} key={name}>
        <LoggingClick logName='hh_gnb_click' data={{ button_text: name }}>
          <S.StyledAccordionTrigger>
            <Text as='span' font='subTitle3' color={vars.text.primary}>
              {name}
            </Text>
            <AnimatePresence mode='popLayout'>
              <motion.span
                key={open ? 'up' : 'down'}
                initial={{ opacity: 0.3 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.3 }}
                transition={{ duration: ANIMATION_DURATION }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <When
                  condition={open}
                  fallback={
                    <ArrowChevronDown size={20} color={vars.icon.primary} />
                  }
                >
                  <ArrowChevronUp size={20} color={vars.icon.primary} />
                </When>
              </motion.span>
            </AnimatePresence>
          </S.StyledAccordionTrigger>
        </LoggingClick>
        <S.StyledAccordionContent>
          <InnerAccordion items={items} />
        </S.StyledAccordionContent>
      </Accordion.Item>
    </Accordion.Root>
  );
}
