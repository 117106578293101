import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import Link from 'next/link';

import { LoggingClick } from '@/components/common/LoggingClick';
import type { GNBLinkMenu } from '@/components/new-gnb/data';

interface Props extends GNBLinkMenu {}

export function LinkMenu({ url, name, newTab = false }: Props) {
  return (
    <LoggingClick logName='hh_gnb_click' data={{ button_text: name }}>
      <Link
        href={url}
        target={newTab ? '_blank' : '_self'}
        style={{ width: '100%' }}
      >
        <Flex padding='18px 20px' fullWidth>
          <Text as='span' font='subTitle3' color={vars.text.primary}>
            {name}
          </Text>
        </Flex>
      </Link>
    </LoggingClick>
  );
}
