import type { InfiniteData } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import type { MouseEvent } from 'react';

import type {
  GetCommunityPostsResponseDto,
  PollOption,
} from '@/api/community/post';
import { LoggingClick } from '@/components/common/LoggingClick';
import type { CommunityPostCategory } from '@/constants';
import {
  communityPostQueryKey,
  usePatchCommunityPostPoll,
} from '@/hooks/react-query/community/post';

import * as S from './style';

interface Props {
  selectedCategory?: CommunityPostCategory;
  postId: string;
  pollOptions?: PollOption[];
}

/**
 * @todo community 구조 다시 생각해보기, 특히 onSuccess 부분
 */
export function DidNotPoll({ selectedCategory, postId, pollOptions }: Props) {
  const { mutate: patchCommunityPostPoll, isPending } =
    usePatchCommunityPostPoll();
  const queryClient = useQueryClient();

  function handleDidNotPollOptionClick(
    event: MouseEvent<HTMLDivElement>,
    selectedOptionText: string,
  ) {
    event.preventDefault();
    event.stopPropagation();

    if (isPending) {
      return;
    }

    patchCommunityPostPoll(
      {
        postId,
        selectedOptionText,
      },
      {
        onSuccess: (updatedPost) => {
          queryClient.invalidateQueries({
            queryKey: communityPostQueryKey.detail(postId),
          });

          queryClient.invalidateQueries({
            queryKey: communityPostQueryKey.pinned(),
          });

          queryClient.invalidateQueries({
            queryKey: communityPostQueryKey.ongoingPollPosts(),
          });

          if (!selectedCategory) {
            return;
          }

          const queries = queryClient.getQueriesData({
            queryKey: communityPostQueryKey.posts(selectedCategory),
          });
          queries.forEach(([queryKey]) => {
            queryClient.setQueryData(
              queryKey,
              (previousData: InfiniteData<GetCommunityPostsResponseDto>) => {
                if (!previousData) {
                  return previousData;
                }

                return {
                  ...previousData,
                  pages: previousData.pages.map((page) => ({
                    ...page,
                    posts: page.posts.map((post) =>
                      post._id === postId ? updatedPost : post,
                    ),
                  })),
                };
              },
            );
          });
        },
      },
    );
  }

  return (
    <Flex.Column fullWidth gap={6}>
      {pollOptions?.map(({ optionText }, index) => (
        <LoggingClick
          logName='hh_community_post_action'
          key={`${optionText}-${index}`}
          data={{
            post_id: postId,
            action_type: '투표',
          }}
        >
          <S.DidNotPollOption
            onClick={(event) => handleDidNotPollOptionClick(event, optionText)}
          >
            <Text as='span' font='captionSb' color={vars.neutral[30]}>
              {optionText}
            </Text>
          </S.DidNotPollOption>
        </LoggingClick>
      ))}
    </Flex.Column>
  );
}
