import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';
import { motion } from 'framer-motion';

import { DESKTOP } from '@/styles/themes';

export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 21;
  background: rgba(20, 22, 23, 0.88);
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: var(--mobile-gnb-height);
  border-bottom: 1px solid ${vars.neutral[90]};

  ${DESKTOP} {
    height: var(--desktop-gnb-height);
    border-bottom: none;
  }
`;
