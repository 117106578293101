import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import type {
  ButtonHTMLAttributes,
  ComponentProps,
  CSSProperties,
  ReactNode,
} from 'react';

import * as S from './Result.style';

interface ResultProps {
  figure?: ReactNode;
  title: ReactNode;
  description?: ReactNode;
  bottomAddon?: ReactNode;
}
export default function Result({
  title,
  figure,
  description,
  bottomAddon,
}: ResultProps) {
  return (
    <S.ResultContainer>
      {figure != null && (
        <S.ResultFigureWrapper>{figure}</S.ResultFigureWrapper>
      )}
      {title != null && <S.ResultTitleWrapper>{title}</S.ResultTitleWrapper>}
      {description != null && (
        <S.ResultDescription hasTitle={title != null}>
          {description}
        </S.ResultDescription>
      )}
      {bottomAddon != null && (
        <S.ResultBottomAddonContainer>
          {bottomAddon}
        </S.ResultBottomAddonContainer>
      )}
    </S.ResultContainer>
  );
}

function Title({
  as = 'h1',
  font = { mobile: 'subTitle2', desktop: 'title2' },
  color = vars.neutral[0],
  ...restProps
}: Partial<ComponentProps<typeof Text<'h1'>>>) {
  return (
    <Text as={as} color={color} font={font} {...restProps}>
      {restProps.children}
    </Text>
  );
}

function Description({
  as = 'p',
  font = 'bodyM',
  color = vars.neutral[50],
  style: styleFromProps,
  ...restProps
}: Partial<ComponentProps<typeof Text<'p'>>>) {
  const style = {
    whiteSpace: 'pre-wrap',
    ...styleFromProps,
  } as CSSProperties;

  return (
    <Text as={as} color={color} font={font} style={style} {...restProps} />
  );
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

function Button(props: ButtonProps) {
  return <S.ResultButton {...props} />;
}

Result.Title = Title;
Result.Description = Description;
Result.Button = Button;
