import { useSuspenseQuery } from '@tanstack/react-query';
import { Divider } from '@teamsparta/design-system';
import { Separated, When } from '@teamsparta/react';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { isEmptyArray, isNotNil } from '@teamsparta/utils';
import { Fragment } from 'react';

import CustomLink from '@/components/common/CustomLink';
import Only from '@/components/common/Only';
import { Skeleton } from '@/components/common/skeleton';
import { useIsDesktop } from '@/hooks';
import {
  communityNoticesQuery,
  pinnedCommunityPostQuery,
} from '@/hooks/react-query/community/post';
import { breakpoints } from '@/styles/themes';

import { DesktopNotice, MobileNotice } from './components';

export function Notices() {
  const { data: notices } = useSuspenseQuery(communityNoticesQuery());
  const { data: pinnedPost } = useSuspenseQuery(pinnedCommunityPostQuery());
  const isDesktop = useIsDesktop({ breakpoint: breakpoints.largeTablet });

  return (
    <When condition={!isEmptyArray(notices)}>
      <Only.Desktop>
        <Divider color={vars.neutral[90]} />
      </Only.Desktop>
      <Flex.Column
        gap={{ mobile: 0, desktop: 16 }}
        fullWidth
        style={{ marginTop: isDesktop ? 0 : '-16px' }}
      >
        <Only.Desktop>
          <Text
            as='span'
            font={{ mobile: 'subTitle3', desktop: 'bodyB' }}
            color={vars.neutral[5]}
          >
            주목할 게시글
          </Text>
        </Only.Desktop>
        <Flex.Column gap={16} fullWidth>
          <When condition={isNotNil(pinnedPost)}>
            <Only.Mobile>
              <Divider color={vars.neutral[90]} size={8} />
            </Only.Mobile>
          </When>
          <Separated
            with={
              <Only.Mobile>
                <Divider color={vars.neutral[90]} />
              </Only.Mobile>
            }
          >
            {notices.map((notice) => (
              <CustomLink
                key={notice._id}
                href={`/community/post/${notice._id}`}
                fullWidth
              >
                <Fragment>
                  <Only.Desktop>
                    <DesktopNotice
                      postId={notice._id}
                      title={notice.title}
                      author={notice.author}
                      createdAt={notice.createdAt}
                    />
                  </Only.Desktop>
                  <Only.Mobile>
                    <MobileNotice
                      postId={notice._id}
                      title={notice.title}
                      createdAt={notice.createdAt}
                    />
                  </Only.Mobile>
                </Fragment>
              </CustomLink>
            ))}
          </Separated>
          <Only.Mobile>
            <Divider color={vars.neutral[90]} size={8} />
          </Only.Mobile>
        </Flex.Column>
      </Flex.Column>
    </When>
  );
}

function _Skeleton() {
  return (
    <>
      <Only.Desktop>
        <Divider color={vars.neutral[90]} />
      </Only.Desktop>
      <Only.Desktop>
        <Flex.Column fullWidth gap={16}>
          <Skeleton style={{ width: '55px', height: '26px' }} />
          <Flex.Column fullWidth gap={16}>
            <Skeleton style={{ width: '100%', height: '40px' }} />
            <Skeleton style={{ width: '100%', height: '40px' }} />
          </Flex.Column>
        </Flex.Column>
      </Only.Desktop>
      <Only.Mobile>
        <Flex.Column fullWidth gap={16}>
          <Divider color={vars.neutral[90]} size={8} />
          <Flex.Column fullWidth padding='0 16px' gap={16}>
            <Skeleton style={{ width: '100%', height: '22px' }} />
          </Flex.Column>
          <Divider color={vars.neutral[90]} />
          <Flex.Column fullWidth padding='0 16px' gap={16}>
            <Skeleton style={{ width: '100%', height: '22px' }} />
          </Flex.Column>
        </Flex.Column>
        <Divider color={vars.neutral[90]} size={8} />
      </Only.Mobile>
    </>
  );
}

Notices.Skeleton = _Skeleton;
