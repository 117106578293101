import { ActionThumbUp } from '@teamsparta/design-system';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import type { MouseEvent } from 'react';

interface Props {
  count?: number;
  liked?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export function LikeButton({ count = 0, liked = false, onClick }: Props) {
  return (
    <Flex.Row align='center' gap={3} as='button' onClick={onClick}>
      <ActionThumbUp
        size={16}
        style={liked ? 'fill' : 'line'}
        color={vars.neutral[20]}
      />
      <Text as='span' font='captionSb' color={vars.neutral[20]}>
        {count}
      </Text>
    </Flex.Row>
  );
}
