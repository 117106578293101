import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import Image from 'next/image';

interface Props {
  count?: number;
  active?: boolean;
}

export function CommentButton({ count = 0 }: Props) {
  return (
    <Flex.Row align='center' gap={3}>
      <Image
        src='/svg/community/speech-bubble-line.svg'
        alt='댓글'
        width={16}
        height={16}
      />
      <Text as='span' font='captionSb' color={vars.neutral[20]}>
        {count}
      </Text>
    </Flex.Row>
  );
}
