import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Content, Trigger } from '@radix-ui/react-accordion';

const slideDown = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 1;
    height: var(--radix-accordion-content-height);
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

export const StyledAccordionTrigger = styled(Trigger)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px 20px;
  transition: transform 0.2s;

  &[data-state='open'] > svg {
    transform: rotate(180deg);
  }
`;

export const StyledAccordionContent = styled(Content)`
  &[data-state='open'] {
    animation: ${slideDown} 0.15s ease-in-out;
  }

  &[data-state='closed'] {
    animation: ${slideUp} 0.15s ease-in-out;
  }
`;
