import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';

import { DESKTOP } from '@/styles/themes';

export const ResultWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0;

  ${DESKTOP} {
    padding: 180px 0;
  }
`;

export const Container = styled.div`
  background-color: ${vars.neutral[100]};
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;
