import { Suspense, When } from '@teamsparta/react';
import { Divider } from '@teamsparta/stack-divider';
import { Flex } from '@teamsparta/stack-flex';
import { ArrowChevronDown, ArrowRight } from '@teamsparta/stack-icons';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';

import { LoggingClick } from '@/components/common/LoggingClick';
import Portal from '@/components/common/Portal';
import type { GNBDropdownMenu } from '@/components/new-gnb/data';
import { debounce } from '@/components/new-gnb/logic';
import { SECOND } from '@/constants';
import { useClickAway, useIsDesktop } from '@/hooks';

import { Badge } from '../../../Badge';
import * as S from './style';

interface Props extends GNBDropdownMenu {
  opened?: boolean;
  open?: () => void;
  close: () => void;
}

const ANIMATION_DURATION = 0.1;
const DEBOUNCE_TIME = (ANIMATION_DURATION + 0.02) * SECOND;
const GNB_DROP_DOWN_MENU_CONTENT_ID = 'gnb-dropdown-menu-content';

/**
 * @todo refactor: 컴포넌트 분리, hook 분리
 */
export function DropdownMenu({
  name,
  items,
  opened = false,
  open,
  close,
}: Props) {
  const [currentDropdownMenuItem, setCurrentDropdownMenuItem] =
    useState<string>(items[0].name);
  const debouncedSetCurrentDropdownMenuItem = debounce(
    setCurrentDropdownMenuItem,
    DEBOUNCE_TIME,
  );
  const detailItems = items.find(
    (item) => item.name === currentDropdownMenuItem,
  )?.detailItems;

  const isSingleItems = items.length === 1;

  const isDesktop = useIsDesktop();

  const triggerRef = useRef<HTMLButtonElement>(null);
  const contentRef = useClickAway<HTMLDivElement>((event) => {
    if (!opened) {
      return;
    }

    if (triggerRef.current?.contains(event.target as Node)) {
      return;
    }

    close();
  });

  return (
    <>
      <LoggingClick logName='hh_gnb_click' data={{ button_text: name }}>
        <S.DropdownMenuTrigger
          ref={triggerRef}
          opened={opened}
          onClick={() => {
            if (opened) {
              close?.();
            } else {
              open?.();
            }
          }}
        >
          <Text as='span' font='bodyM' color={vars.text.secondary}>
            {name}
          </Text>
          <motion.span
            initial={{ transform: opened ? 'rotate(0deg)' : 'rotate(180deg)' }}
            animate={{ transform: opened ? 'rotate(180deg)' : 'rotate(0deg)' }}
            transition={{ duration: 0.2 }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <ArrowChevronDown size={16} color={vars.icon.secondary} />
          </motion.span>
        </S.DropdownMenuTrigger>
      </LoggingClick>
      <When condition={isDesktop}>
        <Portal selector={`#${GNB_DROP_DOWN_MENU_CONTENT_ID}`}>
          <AnimatePresence>
            {opened && (
              <S.DropdownMenuContent
                ref={contentRef}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                <Flex gap={40} style={{ width: 984 }}>
                  <Flex.Column gap={8} style={{ width: 300 }}>
                    {items.map(({ name, description }) => (
                      <div
                        key={name}
                        style={{ position: 'relative', width: '100%' }}
                      >
                        <AnimatePresence mode='wait'>
                          {currentDropdownMenuItem === name &&
                            !isSingleItems && (
                              <motion.div
                                key={name}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: ANIMATION_DURATION }}
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  borderRadius: 8,
                                  background: '#DE19121F',
                                  width: '100%',
                                  height: '100%',
                                  border: `1px solid ${vars.brand.default}`,
                                }}
                              />
                            )}
                        </AnimatePresence>
                        <LoggingClick
                          logName='hh_gnb_click'
                          data={{ button_text: name }}
                        >
                          <Flex.Column
                            gap={isSingleItems ? 4 : 0}
                            padding={isSingleItems ? 0 : 20}
                            style={{
                              position: 'relative',
                              zIndex: 1,
                              borderRadius: 8,
                            }}
                            onMouseEnter={() =>
                              debouncedSetCurrentDropdownMenuItem(name)
                            }
                          >
                            <Text
                              as='span'
                              font='subTitle3'
                              color={vars.text.primary}
                            >
                              {name}
                            </Text>
                            <Text
                              as='p'
                              font='captionM'
                              color={vars.text.tertiary}
                            >
                              {description}
                            </Text>
                          </Flex.Column>
                        </LoggingClick>
                      </div>
                    ))}
                  </Flex.Column>
                  <Divider
                    orientation='vertical'
                    isFlexItem
                    color={vars.neutralWhiteAlpha[8]}
                  />
                  <AnimatePresence mode='popLayout'>
                    <motion.div
                      key={currentDropdownMenuItem}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: ANIMATION_DURATION }}
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        gap: 8,
                      }}
                    >
                      {detailItems?.map(({ name, url, courseType, newTab }) => (
                        <LoggingClick
                          key={name}
                          logName='hh_gnb_click'
                          data={{ button_text: name }}
                        >
                          <S.StyledLink
                            href={url}
                            target={newTab ? '_blank' : '_self'}
                            rel='noopener noreferrer'
                          >
                            <Flex align='center' gap={6} fullWidth>
                              <Text
                                as='span'
                                font='bodyB'
                                color={vars.text.secondary}
                              >
                                {name}
                              </Text>
                              <Suspense clientOnly>
                                <Badge courseType={courseType} />
                              </Suspense>
                            </Flex>
                            <ArrowRight size={20} color={vars.icon.tertiary} />
                          </S.StyledLink>
                        </LoggingClick>
                      ))}
                    </motion.div>
                  </AnimatePresence>
                </Flex>
              </S.DropdownMenuContent>
            )}
          </AnimatePresence>
        </Portal>
      </When>
    </>
  );
}
