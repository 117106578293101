import { ArrowRightLine } from '@teamsparta/design-system';
import { vars } from '@teamsparta/stack-tokens';
import Image from 'next/legacy/image';
import type { ReactNode } from 'react';

import type { Course } from '@/types';

import { _99CLUB_DISCORD_URL, _99CLUB_STUDY_GUIDE_URL } from '../99club';

export type MyPageGnbItem = {
  name: string;
  url: string;
  icon: ReactNode;
  isNewTab?: boolean;
};

export const MY_PAGES: Array<{ name: string; href: string }> = [
  {
    name: '나의 수강',
    href: '/users/my-page?tab=나의 수강',
  },
  {
    name: '결제 내역',
    href: '/users/my-page?tab=결제 내역',
  },
  {
    name: '계정 정보',
    href: '/users/my-page?tab=계정 정보',
  },
];

export const MY_PAGE_GNB_BUTTONS: Partial<Record<Course, MyPageGnbItem[]>> = {
  '백엔드 커리어 코칭': [
    {
      name: '코칭 예약',
      url: '/coaching/reservation',
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
  ],
  '프론트엔드 커리어 코칭': [
    {
      name: '코칭 예약',
      url: '/coaching/reservation',
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
  ],
  '플러스 백엔드 코스': [
    {
      name: '출석 체크',
      url: `/attendance`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '학습 랭킹',
      url: `/leaderboard`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '평가 현황',
      url: '/evaluation',
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '멘토링 예약',
      url: '/plus/mentoring',
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '과제 제출',
      url: `/assignments`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '교육 평가',
      url: `/survey`,
      isNewTab: true,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
  ],
  '플러스 프론트엔드 코스': [
    {
      name: '출석 체크',
      url: `/attendance`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '학습 랭킹',
      url: `/leaderboard`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '평가 현황',
      url: '/evaluation',
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '멘토링 예약',
      url: '/plus/mentoring',
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '과제 제출',
      url: `/assignments`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '교육 평가',
      url: `/survey`,
      isNewTab: true,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
  ],
  '플러스 AI 코스': [
    {
      name: '출석 체크',
      url: `/attendance`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '학습 랭킹',
      url: `/leaderboard`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '평가 현황',
      url: '/evaluation',
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '멘토링 예약',
      url: '/plus/mentoring',
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '과제 제출',
      url: `/assignments`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '교육 평가',
      url: `/survey`,
      isNewTab: true,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
  ],
  '취업 리부트 코스': [
    {
      name: '출석 체크',
      url: `/attendance`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '학습 랭킹',
      url: `/leaderboard`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
  ],
  '99클럽 코딩테스트 스터디': [
    {
      name: '99클럽 홈',
      url: `/99club/lms`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '레벨 미션',
      url: `/99club/levels`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '문제 모아보기',
      url: `/99club/problems`,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '디스코드',
      url: _99CLUB_DISCORD_URL,
      isNewTab: true,
      icon: (
        <Image
          src='/images/icons/link-icon-light-gray.svg'
          width={16}
          height={16}
          alt='signin-icon'
        />
      ),
    },
    {
      name: '스터디 가이드',
      url: _99CLUB_STUDY_GUIDE_URL,
      isNewTab: true,
      icon: (
        <Image
          src='/images/icons/link-icon-light-gray.svg'
          width={16}
          height={16}
          alt='signin-icon'
        />
      ),
    },
  ],
  '24이노캠': [
    {
      name: '출석 체크',
      url: '/attendance',
      isNewTab: false,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '학습 랭킹',
      url: '/leaderboard',
      isNewTab: false,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
    {
      name: '과제 제출',
      url: '/assignments',
      isNewTab: false,
      icon: <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />,
    },
  ],
};
