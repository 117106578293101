import { useSuspenseQuery } from '@tanstack/react-query';
import { vars } from '@teamsparta/stack-tokens';
import { isNil, isNotNil } from '@teamsparta/utils';
import { addDays, isAfter, isBefore } from 'date-fns';

import type { EarlyBirdPolicy } from '@/api/common/round';
import { HOUR } from '@/constants';
import { enrollableRoundQuery } from '@/hooks/react-query/common/round';
import { adjustDateByPurpose } from '@/lib/utils/date';
import type { Course } from '@/types';

const BADGES = {
  모집_중: {
    name: '모집 중',
    textColor: vars.deepRed[10],
    backgroundColor: vars.deepRed[90],
  },
  마감_임박: {
    name: '마감 임박',
    textColor: vars.deepRed[10],
    backgroundColor: vars.deepRed[80],
  },
  할인_마감_임박: {
    name: '할인 마감 임박',
    textColor: vars.mint[10],
    backgroundColor: vars.mint[90],
  },
  첫_기수_무료: {
    name: '첫 기수 무료',
    textColor: vars.yellow[10],
    backgroundColor: vars.yellow[100],
  },
};

/**
 * @see https://www.notion.so/teamsparta/LNB-1ba2dc3ef5148063a65bcdb0dbbb2c51?pvs=4
 * @todo description 주석 달기(다이어그램으로)
 * @todo 테스트 코드
 */
export function useGetCourseBadge({ courseType }: { courseType: Course }) {
  const { data: 모집_가능한_기수 } = useSuspenseQuery({
    ...enrollableRoundQuery(courseType),
    staleTime: HOUR,
    gcTime: 2 * HOUR,
  });

  if (첫_기수_무료로_반환해야_하는_예외_케이스(courseType)) {
    return BADGES.첫_기수_무료;
  }

  const 모집_중 = isNotNil(모집_가능한_기수);
  if (!모집_중) {
    return null;
  }
  if (모집_마감_5일_이내(모집_가능한_기수.enrollEndDate)) {
    return BADGES.마감_임박;
  }

  const 얼리버드_정책 = 모집_가능한_기수?.earlyBirdPolicy;
  if (얼리버드가_다_종료되었거나_존재하지_않음(얼리버드_정책)) {
    return BADGES.모집_중;
  }
  if (얼리버드_마감_3일_이내(얼리버드_정책)) {
    return BADGES.할인_마감_임박;
  }

  return BADGES.모집_중;
}

/**
 * @description 2025년 3월 말 기준으로 아직 1일 1면접/아티클 챌린지는 DB화가 되어 있지 않아 다음과 같이 하드코딩으로 처리
 * @todo 추후 1일 1면접/아티클 챌린지가 DB화 되면 해당 조건문을 삭제해도 됨.
 */
function 첫_기수_무료로_반환해야_하는_예외_케이스(courseType: Course) {
  return courseType === '면접/아티클 챌린지';
}

function 얼리버드가_다_종료되었거나_존재하지_않음(
  얼리버드_정책: EarlyBirdPolicy | EarlyBirdPolicy[],
) {
  const 가장_가까운_얼리버드_정책 =
    가장_가까운_얼리버드_정책_가져오기(얼리버드_정책);

  return isNil(가장_가까운_얼리버드_정책);
}

function 얼리버드_마감_3일_이내(
  얼리버드_정책: EarlyBirdPolicy | EarlyBirdPolicy[],
) {
  const 가장_가까운_얼리버드_정책 =
    가장_가까운_얼리버드_정책_가져오기(얼리버드_정책);

  if (isNil(가장_가까운_얼리버드_정책)) {
    return false;
  }

  return isAfter(
    addDays(new Date(), 3),
    adjustDateByPurpose(가장_가까운_얼리버드_정책.endDate, 'calc'),
  );
}

function 모집_마감_5일_이내(enrollEndDate: Date) {
  return isAfter(
    addDays(new Date(), 5),
    adjustDateByPurpose(enrollEndDate, 'calc'),
  );
}

function 가장_가까운_얼리버드_정책_가져오기(
  earlybirdPolicy: EarlyBirdPolicy | EarlyBirdPolicy[],
) {
  const now = new Date();
  /**
   * @todo @teamsparta/utils isArray 함수 사용
   */
  const earlybirdPolicies = Array.isArray(earlybirdPolicy)
    ? earlybirdPolicy
    : [earlybirdPolicy];

  return earlybirdPolicies.find((policy) =>
    isBefore(now, adjustDateByPurpose(policy.endDate, 'calc')),
  );
}
