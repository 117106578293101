/**
 * @todo refactor: 따로 분리하기
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<T>) => {
    // 이전 타이머가 있으면 제거
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    // 새 타이머 설정
    timeout = setTimeout(() => {
      func(...args);
      timeout = null;
    }, wait);
  };

  // 타이머 취소 메서드 추가
  debounced.cancel = () => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return debounced;
}
