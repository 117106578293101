import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';
import { useEffect, useState } from 'react';

import Portal from '../Portal';

export function StackDevtools({
  initialOpen = true,
}: {
  initialOpen?: boolean;
}) {
  const [themeElements, setThemeElements] = useState<HTMLElement[]>([]);
  const [isOpen, setIsOpen] = useState(initialOpen);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' || !isOpen) {
      return;
    }

    // 디바운스 적용을 위한 타이머
    let debounceTimer: NodeJS.Timeout | null = null;

    // data-stack-color-theme 속성을 가진 모든 요소 찾기
    const findThemeElements = () => {
      const elements = Array.from(
        document.querySelectorAll('[data-stack-color-theme]'),
      ) as HTMLElement[];

      setThemeElements(elements);
    };

    // 초기 요소들 찾기
    findThemeElements();

    // DOM 변경 감지를 위한 MutationObserver 설정
    const observer = new MutationObserver((mutations) => {
      let shouldUpdate = false;

      for (const mutation of mutations) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-stack-color-theme'
        ) {
          shouldUpdate = true;
          break;
        } else if (
          mutation.type === 'childList' &&
          Array.from(mutation.addedNodes).some(
            (node) =>
              node.nodeType === Node.ELEMENT_NODE &&
              (node as Element).querySelector('[data-stack-color-theme]'),
          )
        ) {
          shouldUpdate = true;
          break;
        }
      }

      if (shouldUpdate) {
        // 디바운싱 적용: 짧은 시간 내 여러 변경사항을 하나로 묶음
        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
          findThemeElements();
        }, 1000);
      }
    });

    // 문서 전체 관찰 시작
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
      attributeFilter: ['data-stack-color-theme'],
    });

    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      observer.disconnect();
    };
  }, [isOpen]);

  return (
    <Portal selector='body'>
      {themeElements.map((element, index) => {
        const rect = element.getBoundingClientRect();
        const theme = element.getAttribute('data-stack-color-theme');

        return (
          <DevtoolsContainer
            key={index}
            onClick={() => setIsOpen(!isOpen)}
            style={
              {
                ['--top']: `${window.scrollY + rect.top}px`,
                ['--left']: `${window.scrollX + rect.left}px`,
                ['--color']: vars.text.primary,
                ['--opacity']: isOpen ? 1 : 0.1,
              } as React.CSSProperties
            }
          >
            theme: {theme}
          </DevtoolsContainer>
        );
      })}
    </Portal>
  );
}

const DevtoolsContainer = styled.div`
  position: absolute;
  top: var(--top);
  left: var(--left);
  background-color: white;
  border: 2px solid #121212;
  color: #121212;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  z-index: 9999;
  opacity: var(--opacity);
  transition: opacity 0.3s ease;
`;
