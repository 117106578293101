import { URL } from 'url';

import { PLUS_BACKEND, PLUS_FRONTEND } from '@/constants';
import { getCookie, setCookie } from '@/lib/utils/cookie';
import type { CourseType } from '@/types';

import { videosNotAvailable } from './data';

export const createDeviceId = () => {
  let result = [];
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 20; i++) {
    result.push(chars.charAt(Math.floor(Math.random() * chars.length)));
  }
  return result.join('');
};

export const getDeviceId = () => {
  if (!getCookie('device_id')) {
    setCookie('device_id', createDeviceId(), 60);
  }
  return getCookie('device_id');
};

type Time = number | string;

export const secondsToString = (seconds: number, type?: string) => {
  const hourToSeconds = 3600;
  let hours: Time = Math.floor(seconds / hourToSeconds);
  let minutes: Time = Math.floor((seconds - hours * hourToSeconds) / 60);

  // 함수로 리팩토링 => 성능올릴 수도
  if (hours < 10 && hours !== 0) {
    hours = '0' + hours;
  }
  if (minutes < 10 && minutes !== 0) {
    minutes = '0' + minutes;
  }

  return type === 'ko' ? `${hours}시간 ${minutes}분` : `${hours}h ${minutes}m`;
};

export const youtubeUrlConverter = (originalUrl: string) => {
  if (!originalUrl || originalUrl.length === 0) {
    return null;
  }
  let regExp =
    /(youtube(-nocookie)?\.com|youtu\.be)\/(watch\?v=|v\/|u\/|embed\/?)?([\w-]{11})(.*)?/i;
  let parsedUrl = null;
  let match = regExp.exec(originalUrl);

  if (match && match[4]) {
    parsedUrl = 'https://www.youtube' + '.com/embed/' + match[4];
  }

  if (originalUrl.includes('studio.youtube.com')) {
    const urlArray = originalUrl.split('/');
    parsedUrl = 'https://www.youtube' + '.com/embed/' + urlArray[4];
  }

  return parsedUrl;
};

export const useFillHyphenIntoPhoneNumber = () => {
  return function (phone: number | string) {
    phone = String(phone).split('-').join('');
    if (phone.length < 4) {
      return phone;
    }
    if (phone.length < 8) {
      return phone.slice(0, 3) + '-' + phone.slice(3);
    }
    if (phone.length < 11) {
      return phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
    }
    return phone.slice(0, 3) + '-' + phone.slice(3, 7) + '-' + phone.slice(7);
  };
};

export const isValidDate = (dateString: string): boolean => {
  const dateParts = dateString.split('-');
  if (dateParts.length !== 3) {
    return false;
  }

  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10);
  const date = new Date(year, month, day);

  const parsedDateString = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

  return dateString === parsedDateString;
};

export const formatDate = (interviewResponse) => {
  const splitDate = interviewResponse?.interviewDate.split('-');
  const splitTime = interviewResponse?.interviewTime.split(':');
  const dateInfo = {
    year: splitDate[0],
    month: splitDate[1] - 1,
    date: splitDate[2],
    day: '',
    hour: splitTime[0],
    minute: splitTime[1],
  };
  const { year, month, date, hour, minute } = dateInfo;
  const interviewTime = new Date(year, month, date, hour, minute);
  switch (interviewTime.getDay()) {
    case 0:
      dateInfo.day = '일';
      break;
    case 1:
      dateInfo.day = '월';
      break;
    case 2:
      dateInfo.day = '화';
      break;
    case 3:
      dateInfo.day = '수';
      break;
    case 4:
      dateInfo.day = '목';
      break;
    case 5:
      dateInfo.day = '금';
      break;
    case 6:
      dateInfo.day = '토';
      break;
  }

  return dateInfo;
};

export const isVideoAvailable = (round: string, url: string): Boolean =>
  !videosNotAvailable[round]?.includes(url) &&
  (url.includes('youtube') || url.includes('youtu.be'));

export interface courseTypeAndName {
  type: CourseType;
  name: string;
}

export const parseCourseType = (roundName): courseTypeAndName => {
  if (roundName?.includes('백엔드')) {
    return { type: PLUS_BACKEND, name: '플러스' };
  }
  if (roundName?.includes('프론트엔드')) {
    return { type: PLUS_FRONTEND, name: '플러스' };
  }
};

export const DAYS = ['일', '월', '화', '수', '목', '금', '토'];

export function getStartDayIndexByCourse(course: string): number {
  switch (course) {
    case PLUS_BACKEND:
      return DAYS.indexOf('토');
    default:
      return DAYS.indexOf('금');
  }
}
export function getDaysByCourse(course) {
  const startDayIndex = getStartDayIndexByCourse(course);
  const daysByCourse = [];
  const daysIndexByCourse = [];
  for (let i = 0; i < 7; i++) {
    const l = (i + startDayIndex) % 7;
    daysByCourse.push(DAYS[l]);
    daysIndexByCourse.push(l);
  }
  return { days: daysByCourse, index: daysIndexByCourse };
}

/**
 * 정상적인 courseType인지 체크
 * @param course
 */
export function validateCourseType(course: string): boolean {
  return [
    'fullTime',
    'plus-fe',
    'plus-be',
    'plus-ai',
    'pre',
    'pm',
    'reboot',
  ].includes(course);
}

// Date 객체를 받아서 연, 월, 일, 요일, 시, 분 을 반환하는 함수
export function parseDate(date: Date): {
  year: number;
  month: number;
  day: number;
  dayOfWeek: string;
  hour: number;
  minute: number;
} {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dayOfWeek = DAYS[date.getDay()];
  const hour = date.getHours();
  const minute = date.getMinutes();
  return {
    year,
    month,
    day,
    dayOfWeek,
    hour,
    minute,
  };
}

/**
 * 핵클 원격 구성 응답 객체를 가지고 SDK_NOT_READY가 아니면서 테스트 그룹이 인자로 받은 testGroup과 같으면 true 반환
 * 여타 문제로 핵클에서 정상적으로 abDecisionObject 를 받아오지 못하는 경우에는 false 반환
 * 진행 중인 ABT가 없을 때는 사용되지 않는 함수이므로 no usages 라도 삭제하지 말기
 */

export function processUrl(req) {
  const protocol = JSON.parse(process.env.IS_DEV) ? 'http' : 'https';
  const fullUrl = `${protocol}://${req.headers.host}${req.url}`;
  let url;

  try {
    url = new URL(fullUrl);
  } catch (error) {
    return null;
  }

  const params = url.searchParams;
  let redirectRequired = false;

  for (let [key, value] of params.entries()) {
    try {
      const decodedValue = decodeURIComponent(value);

      // Add the specific special characters you want to check here
      const specialCharacters = ['�'];

      if (specialCharacters.some((char) => decodedValue.includes(char))) {
        params.set(key, 'naver');
        redirectRequired = true;
      }
    } catch (error) {
      params.set(key, 'naver');
      redirectRequired = true;
    }
  }

  if (redirectRequired) {
    return {
      redirect: {
        destination: url.toString(),
        permanent: false,
      },
    };
  }
  return null;
}

export function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
