import styled from '@emotion/styled';

import type { ScrollDirection } from '@/lib/utils/businessLogics/_common/useScrollDirection';
import { DESKTOP, LARGE_TABLET } from '@/styles/themes';

export const CategoryContainer = styled.ul<{
  scrollDirection: ScrollDirection;
}>`
  display: flex;
  gap: 8px;
  padding: 6px 16px;
  width: 100%;
  position: sticky;
  top: ${({ scrollDirection }) =>
    scrollDirection === 'up' ? 'var(--mobile-gnb-height)' : 0};
  background-color: rgba(20, 22, 23, 0.8);
  backdrop-filter: blur(10px);
  z-index: 2;
  transition: top 0.3s ease-in-out;
  margin: 0;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  ${LARGE_TABLET} {
    padding: 6px 0;
  }

  /**
   * !중요한 내용의 주석이 아님. 그저 단순 css 관련임!
   * 커뮤니티의 경우 LARGE_TABLET을 breakpoint로 사용하지만, GNB는 여전히 DESKTOP을 breakpoint로 사용하고 있음
   * GNB가 여전히 DESKTOP을 breakpoint로 사용하고 있는 건 공은지님(디자이너)과 상의됨. 오히려 이것이 좋다고 하심.
   * GNB와의 갭을 맞추기 위해 여기서는 DESKTOP을 breakpoint로 사용함.
   * 만약 LARGE_TABLET을 사용하고 900px쯤에서 스크롤을 내리다보면 갭이 보일거임
   */
  ${DESKTOP} {
    padding: 6px 0;
    top: ${({ scrollDirection }) =>
      scrollDirection === 'up' ? 'var(--desktop-gnb-height)' : '0'};
  }
`;
