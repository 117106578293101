import styled from '@emotion/styled';

export const Container = styled.div<{ opened: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 50px 0 130px;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transform: ${({ opened }) =>
    opened ? 'translate3d(0vw, 0, 0)' : 'translate3d(100vw, 0, 0)'};
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: rgba(29, 32, 33, 0.64);
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(20px);
  z-index: 20;

  &::-webkit-scrollbar {
    display: none;
  }
`;
