import { ArrowRightLine, Divider } from '@teamsparta/design-system';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRef, useState } from 'react';

import { LoggingClick } from '@/components/common/LoggingClick';
import OutsideClickedWrapper from '@/components/common/OutsideClickedWrapper';
import { MY_PAGES } from '@/constants';
import { useOnlineUser } from '@/hooks';
import { logout } from '@/lib/utils/businessLogics/login/login';
import { env } from '@/lib/utils/env';
import { isClient } from '@/lib/utils/helpers/enviroment';

import * as S from './UserGnbButton.style';

export default function UserGnbButton() {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const timerId = useRef<NodeJS.Timer | null>(null);
  const userInfo = useOnlineUser();

  function handleHover(trigger: 'enter' | 'leave') {
    return function () {
      if (trigger === 'enter') {
        if (timerId.current) {
          clearTimeout(timerId.current);
        }
        setTooltipVisible(true);
      } else {
        timerId.current = setTimeout(() => {
          setTooltipVisible(false);
        }, 300);
      }
    };
  }

  return (
    <S.ButtonWrapper
      onMouseEnter={handleHover('enter')}
      onMouseLeave={handleHover('leave')}
      onClick={handleHover('enter')}
    >
      <Image
        src='/images/icons/person_icon.png'
        width={24}
        height={24}
        alt='signin-icon'
      />
      <OutsideClickedWrapper setState={setTooltipVisible}>
        <S.TooltipBox visible={tooltipVisible}>
          <Text
            as='span'
            font='bodyB'
            color={vars.neutral[0]}
            style={{
              padding: '10px 0',
            }}
          >
            안녕하세요, {userInfo?.name} 님!
          </Text>
          <S.LinkButtonList>
            {MY_PAGES.map(({ name, href }) => (
              <LoggingClick
                key={name}
                logName='hh_gnb_click'
                data={{
                  button_text: name,
                  button_href: `${env.NEXT_PUBLIC_PAGE_URL}${href}`,
                  page_title: isClient() ? document.title : '',
                }}
              >
                <Link href={href} passHref>
                  <Flex.Row
                    align='center'
                    justify='between'
                    style={{
                      padding: '10px 0',
                    }}
                  >
                    <Text as='span' font='captionM' color={vars.neutral[0]}>
                      {name}
                    </Text>
                    <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />
                  </Flex.Row>
                </Link>
              </LoggingClick>
            ))}
          </S.LinkButtonList>
          <Divider color={vars.neutral[70]} />
          <S.GnbLogoutButton onClick={logout}>로그아웃</S.GnbLogoutButton>
        </S.TooltipBox>
      </OutsideClickedWrapper>
    </S.ButtonWrapper>
  );
}
