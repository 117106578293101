import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { isNil } from '@teamsparta/utils';

import type { Course } from '@/types';

import { useGetCourseBadge } from './logic';

interface Props {
  courseType: Course;
}

export function Badge({ courseType }: Props) {
  const badge = useGetCourseBadge({ courseType });

  if (isNil(badge)) {
    return null;
  }

  return (
    <Flex
      padding='1px 4px'
      align='center'
      justify='center'
      style={{ borderRadius: 4, backgroundColor: badge.backgroundColor }}
    >
      <Text as='span' font='tag2' color={badge.textColor}>
        {badge.name}
      </Text>
    </Flex>
  );
}
