import { useBoolean, When } from '@teamsparta/react';
import { Flex } from '@teamsparta/stack-flex';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useScrollDirection } from '@/lib/utils/businessLogics/_common/useScrollDirection';
import { useEditProfileModal } from '@/store/community';

import Only from '../common/Only';
import { DropdownMenus, Hamburger, Logo, SideBar, User } from './components';
import * as S from './style';
import { useSetAtom } from 'jotai';
import { MobileGNBOpenAtom } from '@/store/landing';

/**
 * @todo refactor: hook 분리
 */
export function GNB() {
  const setMobileGNBOpen = useSetAtom(MobileGNBOpenAtom);
  const {
    value: sideBarOpen,
    toggle: toggleSideBar,
    setFalse: closeSideBar,
  } = useBoolean();
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', closeSideBar);
    return () => {
      router.events.off('routeChangeComplete', closeSideBar);
    };
  }, [router.events, closeSideBar]);

  const { open: openEditProfileModal } = useEditProfileModal();
  function handleClickEditProfile() {
    closeSideBar();
    openEditProfileModal();
  }

  const scrollDirection = useScrollDirection();

  return (
    <>
      <S.Container
        animate={{ y: scrollDirection === 'up' ? 0 : -100 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
      >
        <Flex
          justify='between'
          align='center'
          padding='12px 16px'
          fullWidth
          fullHeight
          style={{ maxWidth: 1232 }}
        >
          <Flex gap={32} align='center' fullWidth>
            <Logo />
            <Only.Desktop>
              <When condition={scrollDirection === 'up'}>
                <DropdownMenus />
              </When>
            </Only.Desktop>
          </Flex>
          <Only.Desktop>
            <User />
          </Only.Desktop>
          <Only.Mobile>
            <Hamburger
              open={sideBarOpen}
              onClick={() => {
                setMobileGNBOpen((prev) => !prev);
                toggleSideBar();
              }}
            />
          </Only.Mobile>
        </Flex>
      </S.Container>
      <SideBar
        opened={sideBarOpen}
        close={closeSideBar}
        onClickEditProfile={handleClickEditProfile}
      />
    </>
  );
}
