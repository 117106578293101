import { FRONTEND_CAREER_COACHING } from '@/constants';
import type { CourseType } from '@/types';

const RECRUIT_COURSE_DATA: CourseType[] = [
  // '99CLUB_NEXT_STUDY',
  // '99CLUB_DOCKER_STUDY',
  // '99CLUB_KUBERNETES_STUDY',
  // '99CLUB_TDD_STUDY',
  // '99CLUB_ALGORITHM_STUDY',
  'INTERVIEW_ARTICLE_CHALLENGE',
];

const ALARM_COURSE_DATA: CourseType[] = ['SHORT_TERM_SKILL_UP'];

const READY_COURSE_DATA: CourseType[] = [FRONTEND_CAREER_COACHING];

const DISABLED_COURSE_DATA: CourseType[] = [];

/**
 * 코스별 특수 배지 텍스트를 반환하는 함수
 * - Docker 스터디: '알림 신청'
 * - 알고리즘 강의 스터디: '알림 신청'
 * - 프론트엔드 커리어 코칭: '준비 중'
 * - 그 외 코스: 모집 상태에 따른 기본 배지 텍스트
 */
export function getCourseBadgeText(
  courseType: CourseType | undefined,
  defaultStatusBadgeText: string,
) {
  if (ALARM_COURSE_DATA.includes(courseType as CourseType)) {
    return '알림 신청';
  }

  if (READY_COURSE_DATA.includes(courseType as CourseType)) {
    return '준비 중';
  }

  if (RECRUIT_COURSE_DATA.includes(courseType as CourseType)) {
    return '모집 중';
  }

  if (courseType === '99CLUB_TDD_STUDY') {
    return '';
  }

  if (isDisabledCourse(courseType)) {
    return undefined;
  }

  // 그 외 코스는 모집 상태에 따른 기본 배지 표시
  return defaultStatusBadgeText;
}

export function isDisabledCourse(courseType?: CourseType) {
  if (courseType === FRONTEND_CAREER_COACHING) {
    return true;
  }

  if (!courseType) {
    return false;
  }

  return DISABLED_COURSE_DATA.includes(courseType);
}
