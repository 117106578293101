import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';

import type { PollOption } from '@/api/community/post';

import { PollMetadata } from '../PollMetadata';

interface Props {
  pollEndDate: string;
  pollOptions: PollOption[];
}

export function ReadOnlyPoll({ pollEndDate, pollOptions }: Props) {
  const totalSelectedCount = pollOptions.reduce(
    (acc, { selectedCount }) => acc + selectedCount,
    0,
  );

  return (
    <Flex.Column
      gap={16}
      padding={20}
      fullWidth
      style={{ borderRadius: '12px', border: `1px solid ${vars.neutral[80]}` }}
    >
      <Flex.Column fullWidth gap={6}>
        {pollOptions.map(({ optionText }, index) => (
          <Text
            key={`${optionText}-${index}`}
            as='span'
            font='captionSb'
            color={vars.neutral[5]}
            style={{
              borderRadius: '999px',
              border: `1px solid ${vars.neutral[70]}`,
              opacity: 0.4,
              padding: '10px 12px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {optionText}
          </Text>
        ))}
      </Flex.Column>
      <PollMetadata
        pollEndDate={pollEndDate}
        totalSelectedCount={totalSelectedCount}
      />
    </Flex.Column>
  );
}
