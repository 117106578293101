/**
 * 99클럽 최신 기수 오픈 채팅방 URL
 */
export const _99CLUB_KAKAO_OPEN_CHAT_URL = 'https://open.kakao.com/o/gvA7QKjh';

/**
 * 99클럽 스터디 가이드 URL
 */
export const _99CLUB_STUDY_GUIDE_URL = 'https://99club-subpage.oopy.io/guide';

/**
 * 99클럽 최신 기수 디스코드 URL
 */
export const _99CLUB_DISCORD_URL = 'https://discord.gg/szdFCsBktp';
