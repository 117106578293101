import { useSuspenseQuery } from '@tanstack/react-query';
import {
  ArrowChevronRightSmall,
  ContentsEdit,
  NavigationClose,
} from '@teamsparta/design-system';
import { When } from '@teamsparta/react';
import { Button, IconButton } from '@teamsparta/stack-button';
import { StackThemeProvider } from '@teamsparta/stack-core';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { useRouter } from 'next/router';
import type { MouseEvent } from 'react';

import { LoggingClick } from '@/components/common/LoggingClick';
import Only from '@/components/common/Only';
import { Skeleton } from '@/components/common/skeleton';
import { profileQuery } from '@/hooks/react-query/community/profile';
import { useToken } from '@/hooks/user/useToken';
import { sendLog } from '@/lib/utils/send-log';
import { useEditProfileModal } from '@/store/community';
import { useLoginModal } from '@/store/login';

import { Dialog } from '../Dialog';
import { ProfileImage } from '../ProfileImage';
import { TextArea } from '../TextArea';
import { WritePostButton } from '../WritePostButton';
import { useEditProfile } from './logic';
import { NICKNAME_MAX_LENGTH } from './schema';

export function Profile() {
  const { isLoading, token } = useToken();

  return (
    <When condition={!isLoading}>
      <When condition={Boolean(token)} fallback={<NotLoggedInProfile />}>
        <ProfileContent />
      </When>
    </When>
  );
}

export function ProfileContent() {
  const { data: profile } = useSuspenseQuery(profileQuery);

  const { value: show, open, close } = useEditProfileModal();

  const {
    form,
    reset,
    isPending,
    previewImageUrl,
    imageFileInputRef,
    handleSubmit,
    handleKeyPressNickname,
    handleClickProfileImageEdit,
    handleChangeProfileImage,
  } = useEditProfile({
    profileId: profile?._id,
    defaultImageUrl: profile?.imageUrl,
    defaultNickname: profile?.nickname,
    onSubmit: close,
  });

  function closeDialog() {
    close();
    reset();
  }

  function handleOpenChange(nextOpen: boolean) {
    if (nextOpen) {
      open();
    } else {
      closeDialog();
    }
  }

  if (!profile) {
    return <NotLoggedInProfile />;
  }

  return (
    <Dialog.Root open={show} onOpenChange={handleOpenChange}>
      <Flex.Column fullWidth gap={16}>
        <Flex.Row fullWidth gap={8} align='center'>
          <ProfileImage
            src={profile.imageUrl}
            alt='profile image'
            width={32}
            height={32}
          />
          <Flex.Row align='center' style={{ flex: 1 }}>
            <Text
              as='span'
              style={{ flex: 1, whiteSpace: 'nowrap' }}
              font='bodyM'
              color={vars.neutral[5]}
            >
              {profile.nickname}
            </Text>
            <LoggingClick
              logName='hh_community_click'
              data={{ section: '프로필', text: '프로필 수정' }}
            >
              <Dialog.Trigger asChild onClick={open}>
                <button>
                  <ContentsEdit size={20} color={vars.neutral[60]} />
                </button>
              </Dialog.Trigger>
            </LoggingClick>
          </Flex.Row>
        </Flex.Row>
        <WritePostButton fullWidth />
      </Flex.Column>
      <Dialog.Portal>
        <StackThemeProvider theme='hanghaeDark'>
          <Dialog.Overlay />
          <Dialog.Content>
            <Flex.Column
              as='form'
              fullWidth
              gap={{ mobile: 24, desktop: 32 }}
              padding={{ mobile: '28px 20px', desktop: '32px' }}
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <Dialog.Title asChild>
                <Flex.Row fullWidth justify='between' align='center'>
                  <Text
                    as='h2'
                    font={{ mobile: 'title2', desktop: 'title2' }}
                    color={vars.neutral[0]}
                  >
                    커뮤니티 프로필
                  </Text>
                  <Only.Mobile>
                    <Dialog.Close>
                      <button
                        aria-label='닫기'
                        style={{
                          width: 24,
                          height: 24,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <NavigationClose
                          size={24}
                          color={vars.icon.quaternary}
                        />
                      </button>
                    </Dialog.Close>
                  </Only.Mobile>
                </Flex.Row>
              </Dialog.Title>
              <Flex.Column
                gap={{ mobile: 20, desktop: 24 }}
                align='center'
                fullWidth
              >
                <Flex
                  style={{ position: 'relative' }}
                  onClick={handleClickProfileImageEdit}
                >
                  <input
                    type='file'
                    ref={imageFileInputRef}
                    accept='image/*'
                    hidden
                    onChange={handleChangeProfileImage}
                  />
                  <ProfileImage
                    src={previewImageUrl}
                    alt='프로필 이미지'
                    width={80}
                    height={80}
                  />
                  <IconButton
                    colorScheme='primary'
                    radius='rounded'
                    aria-label='프로필 이미지 수정'
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: -4,
                      boxShadow: '0px 2px 5px 1px rgba(20, 22, 23, 0.24)',
                    }}
                    size='xs'
                    type='button'
                  >
                    <ContentsEdit size={20} color={vars.neutral[5]} />
                  </IconButton>
                </Flex>
                <TextArea.Root
                  id='nickname'
                  max={NICKNAME_MAX_LENGTH}
                  maxLength={NICKNAME_MAX_LENGTH}
                  label={
                    <TextArea.Label htmlFor='nickname'>
                      <Flex.Row align='center' gap={4}>
                        <Text
                          as='span'
                          font='captionSb'
                          color={vars.neutral[40]}
                        >
                          닉네임
                        </Text>
                        <Text
                          as='span'
                          font='captionM'
                          color={vars.neutral[60]}
                        >
                          (2~8자의 한글, 영문, 숫자만 사용 가능)
                        </Text>
                      </Flex.Row>
                    </TextArea.Label>
                  }
                  helperText={
                    <When condition={!!form.formState.errors?.root}>
                      <TextArea.HelperText>
                        {form.formState.errors?.root?.message || ''}
                      </TextArea.HelperText>
                    </When>
                  }
                  addon={
                    <Text
                      as='span'
                      font='captionM'
                      color={vars.neutral[50]}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {`${form.watch('nickname')?.length ?? 0}/8자`}
                    </Text>
                  }
                  {...form.register('nickname')}
                  onKeyPress={handleKeyPressNickname}
                />
              </Flex.Column>
              <Button
                fullWidth
                loading={isPending}
                disabled={!form.formState.isValid || !form.formState.isDirty}
                type='submit'
              >
                저장
              </Button>
              <Only.Desktop>
                <button
                  aria-label='닫기'
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    width: 24,
                    height: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={closeDialog}
                >
                  <NavigationClose size={24} color={vars.neutral[50]} />
                </button>
              </Only.Desktop>
            </Flex.Column>
          </Dialog.Content>
        </StackThemeProvider>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export function NotLoggedInProfile() {
  const router = useRouter();
  const { open, setNextPage } = useLoginModal();

  function handleClick() {
    open();
    setNextPage(router.asPath);

    sendLog('hh_community_click', {
      section: '프로필',
      text: '로그인하기',
    });
  }

  function handleClickWriteButton(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();

    open();
    setNextPage('/community/new');
  }

  function handleClickEditButton() {
    open();
    setNextPage(router.asPath);
  }

  return (
    <Flex.Column
      fullWidth
      gap={16}
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    >
      <Flex.Row fullWidth gap={8} align='center'>
        <ProfileImage alt='profile image' width={32} height={32} />
        <Flex.Row align='center' style={{ flex: 1 }}>
          <Text
            as='span'
            style={{ flex: 1 }}
            font='bodyM'
            color={vars.neutral[5]}
          >
            로그인 하기
          </Text>
          <button onClick={handleClickEditButton}>
            <ArrowChevronRightSmall size={20} color={vars.neutral[60]} />
          </button>
        </Flex.Row>
      </Flex.Row>
      <Button id='write-button' fullWidth onClick={handleClickWriteButton}>
        글쓰기
      </Button>
    </Flex.Column>
  );
}

function _Skeleton() {
  return (
    <Flex.Column fullWidth gap={16}>
      <Flex.Row align='center' gap={8}>
        <Skeleton
          style={{ width: '32px', height: '32px', borderRadius: '50%' }}
        />
        <Skeleton style={{ width: '80px', height: '20px' }} />
      </Flex.Row>
      <Skeleton fullWidth style={{ height: '48px' }} />
    </Flex.Column>
  );
}

Profile.Skeleton = _Skeleton;
