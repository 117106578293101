import { ArrowRightLine, Divider } from '@teamsparta/design-system';
import { useBoolean } from '@teamsparta/react';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { LoggingClick } from '@/components/common/LoggingClick';
import { MY_PAGES } from '@/constants';
import { useClickAway, useOnlineUser } from '@/hooks';
import { logout } from '@/lib/utils/businessLogics/login/login';
import { env } from '@/lib/utils/env';
import { isClient } from '@/lib/utils/helpers/enviroment';

import * as S from './style';

export function Profile() {
  const userInfo = useOnlineUser();

  const { value: opened, setTrue: open, setFalse: close } = useBoolean();
  const buttonRef = useClickAway<HTMLDivElement>(close);
  const tooltipRef = useClickAway<HTMLDivElement>(close);

  return (
    <S.ButtonWrapper ref={buttonRef}>
      <Image
        src='/images/icons/person_icon.png'
        width={24}
        height={24}
        alt='사람 아이콘'
        onClick={open}
        style={{ cursor: 'pointer' }}
      />
      <S.TooltipBox opened={opened} ref={tooltipRef}>
        <Text
          as='span'
          font='bodyB'
          color={vars.neutral[0]}
          style={{ padding: '10px 0', textAlign: 'left' }}
        >
          안녕하세요, {userInfo?.name} 님!
        </Text>
        <S.LinkButtonList>
          {MY_PAGES.map(({ name, href }) => (
            <LoggingClick
              key={name}
              logName='hh_gnb_click'
              data={{
                button_text: name,
                button_href: `${env.NEXT_PUBLIC_PAGE_URL}${href}`,
                page_title: isClient() ? document.title : '',
              }}
            >
              <Link href={href} passHref>
                <Flex.Row
                  align='center'
                  justify='between'
                  style={{ padding: '10px 0' }}
                >
                  <Text as='span' font='captionM' color={vars.neutral[0]}>
                    {name}
                  </Text>
                  <ArrowRightLine size={16} color={`${vars.neutral[50]}`} />
                </Flex.Row>
              </Link>
            </LoggingClick>
          ))}
        </S.LinkButtonList>
        <Divider color={vars.neutral[70]} />
        <S.GnbLogoutButton onClick={logout}>로그아웃</S.GnbLogoutButton>
      </S.TooltipBox>
    </S.ButtonWrapper>
  );
}
