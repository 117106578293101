import { instance } from '@/api/v2/instance';

import type {
  GetNotificationByRoundIdAndOnlineUserIdResponseDto,
  PostNotificationRequestDto,
  PostNotificationResponseDto,
} from './notification.dto';

export async function getNotificationByRoundIdAndOnlineUserId(
  roundId: string,
  onlineUserId: string,
) {
  const { data } =
    await instance.get<GetNotificationByRoundIdAndOnlineUserIdResponseDto>(
      `/v3/notifications/${onlineUserId}/${roundId}`,
    );

  return data.data;
}

export async function postNotification(body: PostNotificationRequestDto) {
  const { data } = await instance.post<PostNotificationResponseDto>(
    '/v3/notifications',
    body,
  );

  return data;
}

export async function patchNotification({
  onlineUserId,
  roundId,
  applyForOrientation,
}: {
  onlineUserId: string;
  roundId: string;
  applyForOrientation: boolean;
}) {
  const { data } = await instance.patch(
    `/v3/notifications/${onlineUserId}/${roundId}`,
    {
      applyForOrientation,
    },
  );

  return data;
}
