import type { HTMLAttributes } from 'react';

import { LoggingClick } from '@/components/common/LoggingClick';

import * as S from './style';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  open: boolean;
}

export function Hamburger({ open, ...props }: Props) {
  return (
    <LoggingClick logName='hh_hub_menuHamburger_click'>
      <S.HamburgerButton data-testid='mobileGnbToggle' {...props}>
        <S.FirstStick open={open} />
        <S.SecondStick open={open} />
        <S.ThirdStick open={open} />
      </S.HamburgerButton>
    </LoggingClick>
  );
}
