import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { useRouter } from 'next/router';

import { useLoginModal } from '@/store/login';

export function LoginButton() {
  const loginModal = useLoginModal();
  const router = useRouter();

  function handleClickLogin() {
    loginModal.open(router.asPath);
  }

  return (
    <Text
      as='button'
      font='bodyM'
      color={vars.neutral[0]}
      onClick={handleClickLogin}
      style={{ whiteSpace: 'nowrap' }}
    >
      로그인
    </Text>
  );
}
