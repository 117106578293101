import { device } from '@/styles/themes';
import { fontPretendard } from '@/styles/themes/variables';
import styled from '@emotion/styled';

export const Wrapper = styled.footer`
  color: #d4d4d4;
  padding: 24px 27px 142px 16px;
  background-color: black;
  font-family: ${fontPretendard};
  /* margin-top: 42px; */
  z-index: 10;
  @media ${device.desktop} {
    padding: 32px 0 48px 0;
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #333;
  @media ${device.desktop} {
    max-width: 1230px;
    flex-direction: row;
    margin: 0 auto 32px auto;
  }
`;

export const SiteMapWrapper = styled.section`
  h4 {
    font-size: 16px;
    margin: 0 0 12px 0;
    @media ${device.desktop} {
      font-size: 18px;
    }
  }
  @media ${device.desktop} {
    width: 440px;
    margin-right: 108px;
  }
`;

export const SiteMap = styled.div`
  display: flex;
  margin: 0 0 20px 0;
  font-size: 14px;
  gap: 20px;
  text-align: left;
  @media ${device.desktop} {
    width: 344px;
    margin-bottom: 32px;
    flex-wrap: nowrap;
    font-size: 16px;
  }
`;

export const ApplyWrapper = styled(SiteMapWrapper)`
  @media ${device.desktop} {
    width: 572px;
  }
`;

export const ApplyItem = styled.div`
  display: flex;
  margin: 0 0 20px 0;
  font-size: 14px;
  gap: 20px;
  text-align: left;
  @media ${device.desktop} {
    justify-content: space-between;
    width: 168px;
    margin-bottom: 27px;
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.section`
  min-width: 90px;
  width: 114px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media ${device.desktop} {
    width: 140px;
    margin-bottom: 0;
  }
`;

export const ImageButton = styled.button`
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  @media ${device.desktop} {
    height: 30px;
  }
`;

export const Terms = styled.section`
  max-width: 1230px;
  margin: 24px auto;
  font-size: 13px;
  a:nth-of-type(1) {
    font-weight: 700;
  }
  a + a {
    padding-left: 15px;
  }
`;

export const CompanyInfo = styled.section`
  @media ${device.desktop} {
    max-width: 1230px;
    margin: auto;
  }
  h4 {
    font-size: 13px;
    @media ${device.desktop} {
      margin: 0 0 8px 0;
    }
  }
`;

export const CompanyDetail = styled.div`
  color: #8b8b8b;
  font-size: 13px;
  font-weight: normal;
  @media ${device.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

export const CopyrightInfo = styled.p`
  margin-top: 16px;
  font-size: 12px;
`;
