import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';

import { LoggingClick } from '@/components/common/LoggingClick';
import { adjustDateByPurpose, getTimePastText } from '@/lib/utils/date';

interface NoticeProps {
  postId: string;
  title: string;
  createdAt: string;
}

export function MobileNotice({ postId, title, createdAt }: NoticeProps) {
  return (
    <LoggingClick
      logName='hh_community_click'
      data={{ section: '공지사항', clicked_post_id: postId }}
    >
      <Flex.Row gap={6} align='center' fullWidth padding='0 16px'>
        <Text
          as='span'
          font='tag1Sb'
          color={vars.brand.strong}
          style={{
            textAlign: 'center',
            borderRadius: '999px',
            padding: '1px 12px',
            backgroundColor: 'rgba(255, 102, 102, 0.24)',
          }}
        >
          공지
        </Text>
        <Text
          as='span'
          font='captionSb'
          color={vars.neutral[10]}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            flex: '1',
            maxWidth: '100%',
          }}
        >
          {title}
        </Text>
        <Text as='span' font='tag2' color={vars.neutral[60]}>
          {getTimePastText(adjustDateByPurpose(createdAt, 'calc'))}
        </Text>
      </Flex.Row>
    </LoggingClick>
  );
}
