import styled from '@emotion/styled';
import { captionM } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px;
  border-radius: 8px;
  border: 1px solid ${vars.neutral[70]};
  gap: 12px;

  &:focus-within {
    border-color: ${vars.neutral[30]};
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  ${captionM};
  color: ${vars.neutral[0]};
  border: none;
  background: transparent;
  resize: none;
  outline: none;
  font-size: 16px;
  transform: scale(0.875);
  transform-origin: left top;
  position: relative;
  top: 2px;

  &:focus {
    border: none;
  }
`;

export const Label = styled.label``;
