import { ArrowLeftSmallLine } from '@teamsparta/design-system';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import Link from 'next/link';

import { getSessionStorageItem } from '@/lib/utils/session-storage';

export function BackToFeed() {
  const category = getSessionStorageItem('category');

  return (
    <Link href={`/community?category=${category}`}>
      <Flex.Row
        align='center'
        gap={4}
        padding={{ mobile: '0 16px', desktop: 0 }}
      >
        <ArrowLeftSmallLine size={16} color={vars.neutral[40]} />
        <Text as='span' font='captionM' color={vars.neutral[40]}>
          목록으로
        </Text>
      </Flex.Row>
    </Link>
  );
}
