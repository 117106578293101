import { getCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

export function useLoggedIn() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userInfo = getCookie('userinfo');
    if (userInfo) {
      setLoggedIn(true);
    }

    setLoading(false);
  }, []);

  return { loggedIn, loading };
}
