import ReactDOM from 'react-dom';

import { useIsMount } from '@/hooks/common/useIsMount';

export default function Portal({ children, selector }) {
  const isMounted = useIsMount();

  if (!isMounted) {
    return null;
  }

  return ReactDOM.createPortal(children, document.querySelector(selector));
}
