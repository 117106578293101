import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { toast } from '@teamsparta/stack-toast';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  profileQuery,
  usePatchProfile,
} from '@/hooks/react-query/community/profile';
import { ApiException } from '@/lib/exceptions';

import type { ProfileSchema } from './schema';
import { NICKNAME_REGEX, profileSchema } from './schema';

interface Props {
  profileId?: string;
  defaultImageUrl?: string;
  defaultNickname?: string;
  onSubmit?: () => void;
}

export function useEditProfile({
  profileId,
  defaultImageUrl,
  defaultNickname,
  onSubmit,
}: Props) {
  const [previewImageUrl, setPreviewImageUrl] = useState(defaultImageUrl);
  const imageFileInputRef = useRef<HTMLInputElement>(null);

  const form = useForm<ProfileSchema>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      nickname: defaultNickname,
    },
  });
  const { refetch } = useQuery(profileQuery);
  const { mutateAsync: patchProfile, isPending } = usePatchProfile();

  const handleSubmit = useCallback(
    async (nextProfile: ProfileSchema) => {
      try {
        if (!profileId) {
          return;
        }

        await patchProfile(
          {
            ...nextProfile,
            profileId,
          },
          {
            onSuccess: (updatedProfile) => {
              form.reset({
                nickname: updatedProfile.nickname,
              });
              setPreviewImageUrl(updatedProfile.imageUrl);
              refetch();
              onSubmit?.();
              toast.success('프로필이 수정되었습니다.');
            },
            onError: (error) => {
              if (error instanceof ApiException) {
                form.setError('root', { message: error.message });
              }
            },
          },
        );
      } catch (error) {
        if (error instanceof ApiException) {
          form.setError('root', { message: error.message });
        }
      }
    },
    [form, onSubmit, patchProfile, profileId, refetch],
  );

  const reset = useCallback(() => {
    setPreviewImageUrl(defaultImageUrl);
    form.reset();
  }, [defaultImageUrl, form]);

  const handleKeyPressNickname = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        if (form.formState.isValid && form.formState.isDirty) {
          form.handleSubmit(handleSubmit)();
        }
      }

      if (event.key === ' ') {
        event.preventDefault();
      }

      const { value } = event.target as HTMLInputElement;
      const filteredValue = value.replace(NICKNAME_REGEX, '');
      form.setValue('nickname', filteredValue);
    },
    [form, handleSubmit],
  );

  const handleClickProfileImageEdit = useCallback(() => {
    imageFileInputRef.current?.click();
  }, []);

  const handleChangeProfileImage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }

      setPreviewImageUrl(URL.createObjectURL(file));
      form.setValue('image', file, { shouldDirty: true });
    },
    [form],
  );

  return {
    form,
    reset,
    isPending,
    previewImageUrl,
    imageFileInputRef,
    handleSubmit,
    handleKeyPressNickname,
    handleClickProfileImageEdit,
    handleChangeProfileImage,
  };
}
