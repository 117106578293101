import { SwitchCase } from '@teamsparta/react';
import { Flex } from '@teamsparta/stack-flex';

import type { GNBDropdownMenu, GNBLinkMenu } from '@/components/new-gnb/data';
import { GNB_MENUS } from '@/components/new-gnb/data';

import { AccordionMenu, LinkMenu } from './components';

export function AccordionMenus() {
  return (
    <Flex.Column padding='10px 0' fullWidth>
      {GNB_MENUS.map((menu) => (
        <SwitchCase
          key={menu.name}
          value={menu.type}
          cases={{
            dropdown: <AccordionMenu {...(menu as GNBDropdownMenu)} />,
            link: <LinkMenu {...(menu as GNBLinkMenu)} />,
          }}
        />
      ))}
    </Flex.Column>
  );
}
