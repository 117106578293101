import styled from '@emotion/styled';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { bodyM, title2 } from '@teamsparta/stack-font';
import { vars } from '@teamsparta/stack-tokens';

import { LARGE_TABLET } from '@/styles/themes';

export const AlertDialogOverlay = styled(AlertDialog.Overlay)`
  position: fixed;
  inset: 0;
  background-color: #141617;
  opacity: 0.6;
  z-index: 1000;
`;

export const AlertDialogContent = styled(AlertDialog.Content)`
  display: flex;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  background: ${vars.neutral[80]};
  box-shadow: 0px 20px 24px 0px rgba(20, 22, 23, 0.24);
  padding: 28px 20px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  position: fixed;
  bottom: 0;
  z-index: 1001;

  ${LARGE_TABLET} {
    width: 460px;
    padding: 32px;
    align-items: flex-start;
    gap: 32px;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
  }
`;

export const AlertDialogTitle = styled(AlertDialog.Title)`
  ${title2};
  color: ${vars.neutral[0]};

  ${LARGE_TABLET} {
    ${title2};
    color: ${vars.neutral[0]};
  }
`;

export const AlertDialogDescription = styled(AlertDialog.Description)`
  ${bodyM};
  color: ${vars.neutral[50]};

  ${LARGE_TABLET} {
    ${bodyM};
    color: ${vars.neutral[50]};
  }
`;
