import { useSuspenseQuery } from '@tanstack/react-query';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';

import { LoggingClick } from '@/components/common/LoggingClick';
import Only from '@/components/common/Only';
import { Skeleton } from '@/components/common/skeleton';
import { pinnedCommunityPostQuery } from '@/hooks/react-query/community/post';

import { Post } from '../Post';

export function MainPost() {
  const { data: post } = useSuspenseQuery(pinnedCommunityPostQuery());

  if (!post) {
    return null;
  }

  return (
    <Flex.Column gap={{ mobile: 10, desktop: 25 }} fullWidth>
      <Flex padding={{ mobile: '0 16px', desktop: 0 }}>
        <Text
          as='span'
          font={{ mobile: 'subTitle3', desktop: 'subTitle3' }}
          color={vars.neutral[5]}
        >
          지금 핫해요 🔥
        </Text>
      </Flex>
      <LoggingClick
        logName='hh_community_click'
        data={{ section: '고정글', clicked_post_id: post._id }}
      >
        <Post {...post} />
      </LoggingClick>
    </Flex.Column>
  );
}

function _Skeleton() {
  return (
    <>
      <Only.Desktop>
        <Flex.Column gap={25} fullWidth>
          <Skeleton style={{ width: '108px', height: '29px' }} />
          <Skeleton style={{ width: '100%', height: '266px' }} />
        </Flex.Column>
      </Only.Desktop>
      <Only.Mobile>
        <Flex.Column gap={10} fullWidth>
          <Flex padding='0 16px'>
            <Skeleton style={{ width: '97px', height: '22px' }} />
          </Flex>
          <Flex fullWidth padding='24px 16px'>
            <Skeleton style={{ width: '100%', height: '203px' }} />
          </Flex>
        </Flex.Column>
      </Only.Mobile>
    </>
  );
}

MainPost.Skeleton = _Skeleton;
