import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';

import { LARGE_TABLET } from '@/styles/themes';

export const SwiperContainer = styled.div`
  width: 100%;

  .swiper-slide {
    width: 280px;
  }
`;

export const OngoingPollsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
  background-color: ${vars.neutral[95]};

  ${LARGE_TABLET} {
    gap: 16px;
    padding: 0;
    background-color: transparent;
  }
`;
