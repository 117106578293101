import { useQueryClient } from '@tanstack/react-query';
import { Separated, SwitchCase, When } from '@teamsparta/react';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { isNotNil } from '@teamsparta/utils';
import type { MouseEvent } from 'react';

import type { CommunityPost } from '@/api/community/post';
import CustomLink from '@/components/common/CustomLink';
import { Dot } from '@/components/common/Dot';
import { LoggingClick } from '@/components/common/LoggingClick';
import Only from '@/components/common/Only';
import type { CommunityPostCategory } from '@/constants';
import { useToken } from '@/hooks';
import { usePostCommunityPostLike } from '@/hooks/react-query/community/like';
import { communityPostQueryKey } from '@/hooks/react-query/community/post';
import { adjustDateByPurpose, getTimePastText } from '@/lib/utils/date';

import { usePoll } from '../../logic';
import { CommentButton } from '../CommentButton';
import { LikeButton } from '../LikeButton';
import {
  BluredPoll,
  DidNotPoll,
  DidPoll,
  PollMetadata,
  PollSkeleton,
} from '../Poll';
import { ProfileImage } from '../ProfileImage';
import { useSafePreviewContent } from './logic';
import * as S from './style';

interface Props extends CommunityPost {
  selectedCategory?: CommunityPostCategory;
}

export function Post({
  selectedCategory,
  _id,
  author,
  category,
  title,
  content,
  likesCount,
  commentsCount,
  poll,
  createdAt,
  isMyLike,
}: Props) {
  const safePreviewContent = useSafePreviewContent(content);

  const { pollComponentType } = usePoll(poll);

  const totalSelectedCount =
    poll?.pollOptions.reduce((acc, curr) => acc + curr.selectedCount, 0) ?? 0;

  // like
  const { mutate: like } = usePostCommunityPostLike({ postId: _id });
  const queryClient = useQueryClient();
  function handleClickLike(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();

    like(
      { postId: _id },
      {
        onSuccess: () => {
          /**
           * @todo community QA로 인해 급하게 해두었음. 낙관적 업데이트 + 전체 invalidate가 아니라 부분 바꿔치기(poll처럼)
           */
          queryClient.invalidateQueries({
            queryKey: communityPostQueryKey.posts(selectedCategory ?? '전체'),
          });
          queryClient.invalidateQueries({
            queryKey: communityPostQueryKey.pinned(),
          });
        },
      },
    );
  }

  const { token } = useToken();

  return (
    <LoggingClick
      logName='hh_community_click'
      data={{ section: '피드', clicked_post_id: _id }}
    >
      <CustomLink href={`/community/post/${_id}`} fullWidth>
        <S.PostContainer>
          <Flex.Column gap={{ mobile: 8, desktop: 10 }} fullWidth>
            <Flex.Row align='center' justify='between' fullWidth>
              <Flex.Row justify='between' align='center' gap={6}>
                <Separated with={<Dot />}>
                  <Flex.Row gap={{ mobile: 6, desktop: 4 }} align='center'>
                    <Only.Mobile>
                      <ProfileImage
                        alt='프로필 이미지'
                        width={17}
                        height={17}
                        src={author.imageUrl}
                      />
                    </Only.Mobile>
                    <Only.Desktop>
                      <ProfileImage
                        alt='프로필 이미지'
                        width={20}
                        height={20}
                        src={author.imageUrl}
                      />
                    </Only.Desktop>
                    <Text
                      as='span'
                      font={{ mobile: 'tag2', desktop: 'tag1Sb' }}
                      color={vars.neutral[50]}
                    >
                      {author.nickname}
                    </Text>
                  </Flex.Row>
                  <Text
                    as='span'
                    font={{ mobile: 'tag2', desktop: 'tag1Sb' }}
                    color={vars.neutral[70]}
                  >
                    {getTimePastText(adjustDateByPurpose(createdAt, 'calc'))}
                  </Text>
                </Separated>
              </Flex.Row>
              <Text
                as='span'
                font={{ mobile: 'tag2', desktop: 'tag1Sb' }}
                color={vars.neutral[70]}
              >
                {category}
              </Text>
            </Flex.Row>
            <Flex.Column gap={{ mobile: 2, desktop: 3 }} fullWidth>
              <S.PostTitle>{title}</S.PostTitle>
              <S.PostContent>{safePreviewContent}</S.PostContent>
            </Flex.Column>
          </Flex.Column>

          <When
            condition={pollComponentType !== 'Loading'}
            fallback={<PollSkeleton />}
          >
            <SwitchCase
              value={pollComponentType}
              cases={{
                BluredPoll: <BluredPoll postId={_id} />,
                DidNotPoll: (
                  <LoggingClick
                    logName='hh_community_action'
                    data={{ action_type: '투표', post_id: _id }}
                  >
                    <DidNotPoll
                      selectedCategory={selectedCategory}
                      postId={_id}
                      pollOptions={poll?.pollOptions}
                    />
                  </LoggingClick>
                ),
                DidPoll: <DidPoll pollOptions={poll?.pollOptions} />,
              }}
            />
          </When>

          <Flex.Row fullWidth justify='between'>
            <Flex.Row gap={12} align='center'>
              <LoggingClick
                logName='hh_community_action'
                data={{ action_type: '좋아요', post_id: _id }}
              >
                <LikeButton
                  count={likesCount}
                  liked={isMyLike && Boolean(token)}
                  onClick={handleClickLike}
                />
              </LoggingClick>
              <CommentButton count={commentsCount} />
            </Flex.Row>
            {isNotNil(poll) && (
              <PollMetadata
                pollEndDate={poll.pollEndDate}
                totalSelectedCount={totalSelectedCount}
              />
            )}
          </Flex.Row>
        </S.PostContainer>
      </CustomLink>
    </LoggingClick>
  );
}
