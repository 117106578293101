import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';

export const DidNotPollOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${vars.neutral[70]};
  border-radius: 999px;
  cursor: pointer;
  padding: 10px 12px;

  &:hover {
    background-color: ${vars.neutral[90]};
  }
`;
