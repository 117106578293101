import 'swiper/css';

import { useSuspenseQuery } from '@tanstack/react-query';
import { Divider } from '@teamsparta/design-system';
import { When } from '@teamsparta/react';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { isEmptyArray } from '@teamsparta/utils';
import { Swiper, SwiperSlide } from 'swiper/react';

import Only from '@/components/common/Only';
import { Skeleton } from '@/components/common/skeleton';
import { useIsDesktop } from '@/hooks';
import { communityOngoingPollPostsQuery } from '@/hooks/react-query/community/post';
import { breakpoints } from '@/styles/themes';

import { OngoingPollPost } from './components';
import * as S from './style';

export function OngoingPollPosts() {
  const { data: ongoingPollPosts } = useSuspenseQuery(
    communityOngoingPollPostsQuery(),
  );

  const isDesktop = useIsDesktop({ breakpoint: breakpoints.largeTablet });
  const isMobile = !isDesktop;

  if (isMobile && ongoingPollPosts.length <= 1) {
    return null;
  }

  return (
    <When condition={!isEmptyArray(ongoingPollPosts)}>
      <Only.Desktop>
        <Divider color={vars.neutral[90]} />
      </Only.Desktop>
      <S.OngoingPollsContainer>
        <Text
          as='span'
          font={{ mobile: 'bodyB', desktop: 'bodyB' }}
          color={vars.neutral[5]}
        >
          진행 중인 투표
        </Text>
        <Only.Desktop>
          <Flex
            direction={{ mobile: 'row', desktop: 'column' }}
            gap={{ mobile: 12, desktop: 16 }}
            fullWidth
          >
            {ongoingPollPosts.map((post) => (
              <OngoingPollPost
                key={post._id}
                postId={post._id}
                title={post.title}
                pollEndDate={post.poll?.pollEndDate ?? ''}
                pollOptions={post.poll?.pollOptions ?? []}
              />
            ))}
          </Flex>
        </Only.Desktop>
        <Only.Mobile>
          <S.SwiperContainer>
            <Swiper
              slidesPerView='auto'
              spaceBetween={12}
              style={{ width: '100%' }}
            >
              {ongoingPollPosts.map((post) => (
                <SwiperSlide key={post.title}>
                  <OngoingPollPost
                    postId={post._id}
                    title={post.title}
                    pollEndDate={post.poll?.pollEndDate ?? ''}
                    pollOptions={post.poll?.pollOptions ?? []}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </S.SwiperContainer>
        </Only.Mobile>
      </S.OngoingPollsContainer>
    </When>
  );
}

function _Skeleton() {
  return (
    <>
      <Only.Desktop>
        <Divider color={vars.neutral[90]} />
      </Only.Desktop>
      <Only.Desktop>
        <Flex.Column fullWidth gap={16}>
          <Skeleton style={{ width: '100px', height: '26px' }} />
          <Flex.Column gap={16} fullWidth>
            <Skeleton style={{ width: '100%', height: '40px' }} />
            <Skeleton style={{ width: '100%', height: '40px' }} />
            <Skeleton style={{ width: '100%', height: '40px' }} />
          </Flex.Column>
        </Flex.Column>
      </Only.Desktop>
      <Only.Mobile>
        <Flex.Column fullWidth padding='24px 16px' gap={8}>
          <Skeleton style={{ width: '80px', height: '22px' }} />
          <Skeleton style={{ width: '280px', height: '73px' }} />
        </Flex.Column>
      </Only.Mobile>
    </>
  );
}

OngoingPollPosts.Skeleton = _Skeleton;
