import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';

import { LARGE_TABLET } from '@/styles/themes';

export const OngoingPollWrapper = styled.div`
  width: 280px;
  min-width: 280px;
  padding: 16px;
  border-radius: 12px;
  background-color: ${vars.neutral[80]};

  ${LARGE_TABLET} {
    width: 100%;
    min-width: 100%;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;
