import { instanceV2 } from '@/api/instance';

import type {
  GetRecentActiveOrientationRequestDto,
  GetRecentActiveOrientationResponseDto,
} from './orientation.dto';

export async function getRecentActiveOrientation({
  courseName,
}: GetRecentActiveOrientationRequestDto) {
  return await instanceV2.get<GetRecentActiveOrientationResponseDto>(
    `/v3/orientations/recent-active/${courseName}`,
  );
}
