import Image from 'next/image';
import Link from 'next/link';

import logo from '/public/images/logo/logo.png';
import Only from '@/components/common/Only';

export function Logo() {
  return (
    <Link
      href='/'
      style={{ display: 'flex', alignItems: 'center', zIndex: 100 }}
    >
      <Only.Desktop>
        <Image src={logo} width={84} height={26} alt='항해 로고' />
      </Only.Desktop>
      <Only.Mobile>
        <Image src={logo} width={77} height={24} alt='항해 로고' />
      </Only.Mobile>
    </Link>
  );
}
