import { useQuery } from '@tanstack/react-query';
import { ContentsEdit } from '@teamsparta/design-system';
import { Separated, When } from '@teamsparta/react';
import { Divider } from '@teamsparta/stack-divider';
import { Flex } from '@teamsparta/stack-flex';
import { ArrowChevronRight } from '@teamsparta/stack-icons';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { isClient, isNotNil } from '@teamsparta/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { LoggingClick } from '@/components/common/LoggingClick';
import Portal from '@/components/common/Portal';
import {
  useIsDesktop,
  useLockBodyScroll,
  useLoggedIn,
  useOnlineUser,
} from '@/hooks';
import { profileQuery } from '@/hooks/react-query/community/profile';
import { logout } from '@/lib/utils/businessLogics/login/login';
import { env } from '@/lib/utils/env';
import { ProfileImage } from '@/pages/community';
import { useLoginModal } from '@/store/login';

import { AccordionMenus } from './components';
import { MY_PAGES } from './data';
import * as S from './style';

interface Props {
  opened?: boolean;
  close: () => void;
  onClickEditProfile?: () => void;
}

/**
 * @todo refactor: hook으로 분리, component 나누기(로그인 유무 기준 등등)
 */
export function SideBar({ opened = false, close, onClickEditProfile }: Props) {
  const { loggedIn } = useLoggedIn();
  const loginModal = useLoginModal();
  const router = useRouter();

  function handleClickLogin() {
    close();
    loginModal.open(router.asPath);
  }

  function handleClickLogout() {
    close();
    logout();
  }

  const isCommunityPage = router.pathname.includes('/community');
  const { data: profile } = useQuery({
    ...profileQuery,
    enabled: isCommunityPage,
  });
  const onlineUser = useOnlineUser();
  const canShowCommunityProfile = isCommunityPage && isNotNil(profile);

  useLockBodyScroll(opened);

  const isDesktop = useIsDesktop();
  const isMobile = !isDesktop;

  return (
    <When condition={isMobile}>
      <Portal selector='#side-bar-overlay'>
        <S.Container opened={opened}>
          <Flex.Column fullWidth padding='32px 20px 24px' gap={20}>
            <When
              condition={loggedIn}
              fallback={
                <Flex
                  as='button'
                  gap={4}
                  align='center'
                  onClick={handleClickLogin}
                  fullWidth
                >
                  <Text as='span' font='subTitle2' color={vars.neutral[0]}>
                    로그인 하기
                  </Text>
                  <ArrowChevronRight size={20} color={vars.icon.secondary} />
                </Flex>
              }
            >
              <Flex align='center' gap={8} fullWidth>
                {canShowCommunityProfile && (
                  <ProfileImage
                    src={profile.imageUrl}
                    alt='프로필 이미지'
                    width={48}
                    height={48}
                  />
                )}
                <Flex.Column gap={2} style={{ flex: 1 }}>
                  <When condition={Boolean(onlineUser?.name)}>
                    <Text as='span' font='subTitle2' color={vars.neutral[0]}>
                      {onlineUser?.name} 님, 안녕하세요.
                    </Text>
                  </When>
                  {canShowCommunityProfile && (
                    <Flex.Row gap={4} align='center'>
                      <Text as='span' font='tag1Sb' color={vars.neutral[40]}>
                        {profile?.nickname}
                      </Text>
                      <LoggingClick
                        logName='hh_hub_menuHamburger_click'
                        data={{ menu_title: '프로필 수정' }}
                      >
                        <Flex as='button' onClick={onClickEditProfile}>
                          <ContentsEdit size={16} color={vars.neutral[60]} />
                        </Flex>
                      </LoggingClick>
                    </Flex.Row>
                  )}
                </Flex.Column>
                <Text
                  as='button'
                  font='tag1M'
                  color={vars.neutral[50]}
                  onClick={handleClickLogout}
                >
                  로그아웃
                </Text>
              </Flex>
              <Flex gap={12} fullWidth justify='evenly'>
                <Separated
                  with={
                    <Divider
                      orientation='vertical'
                      color={vars.neutral[90]}
                      isFlexItem
                    />
                  }
                >
                  {MY_PAGES.map(({ name, url, icon }) => (
                    <LoggingClick
                      key={name}
                      logName='hh_gnb_click'
                      data={{
                        button_text: name,
                        button_href: `${env.NEXT_PUBLIC_PAGE_URL}${url}`,
                        page_title: isClient() ? document.title : '',
                      }}
                    >
                      <Link
                        href={url}
                        style={{
                          display: 'flex',
                          gap: 6,
                          justifyContent: 'center',
                          padding: '10px 0',
                          flex: 1,
                        }}
                      >
                        {icon}
                        <Text as='span' font='captionM' color={vars.neutral[5]}>
                          {name}
                        </Text>
                      </Link>
                    </LoggingClick>
                  ))}
                </Separated>
              </Flex>
            </When>
          </Flex.Column>
          <Divider color={vars.neutralWhiteAlpha[8]} />
          <AccordionMenus />
        </S.Container>
      </Portal>
    </When>
  );
}
