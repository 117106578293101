import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';

import { DESKTOP } from '@/styles/themes';

const Stick = css`
  width: 18px;
  height: 2px;
  background-color: ${vars.neutral[20]};
  border-radius: 16px;
  transform-origin: 0.6px;
`;

export const HamburgerButton = styled.button`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  position: absolute;
  top: 18px;
  right: 21px;
  z-index: 20;
  cursor: pointer;

  ${DESKTOP} {
    display: none;
  }
`;

export const FirstStick = styled.div<{ open: boolean }>`
  ${Stick};
  transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
  margin-bottom: 4px;
  transition: all 0.3s linear;
`;

export const SecondStick = styled.div<{ open: boolean }>`
  ${Stick};
  transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
  opacity: ${({ open }) => (open ? 0 : 1)};
  margin-bottom: 4px;
`;

export const ThirdStick = styled.div<{ open: boolean }>`
  ${Stick};
  transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
  transition: all 0.3s linear;
`;
