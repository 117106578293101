import { useQuery } from '@tanstack/react-query';

type LottieData = object;

interface UseLottieProps {
  /**
   * 로티 데이터 주소
   * @requires
   * @description 원격에 있는 로티 데이터 주소
   */
  src: string;
  /**
   * 로티 데이터 로딩 여부
   * @default true
   * @description 로티 데이터 로딩 여부
   */
  enabled?: boolean;
}

export function useLottie({ src, enabled = true }: UseLottieProps) {
  return useQuery({
    queryKey: ['lottie', src],
    queryFn: () => fetch(src).then((res) => res.json() as Promise<LottieData>),
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
