import { usePreserveCallback } from '@teamsparta/react';
import { useEffect } from 'react';

type EventMap = DocumentEventMap & ElementEventMap;

interface UseEventListenerProps<T extends keyof EventMap> {
  element: Document | HTMLElement;
  event: T;
  callback: (event: EventMap[T]) => void;
  options?: AddEventListenerOptions;
  enabled?: boolean;
}

/**
 * @description DOM 요소에 이벤트 리스너를 등록하고 관리하는 훅
 *
 * 컴포넌트가 마운트될 때 이벤트 리스너를 등록하고,
 * 언마운트될 때 자동으로 이벤트 리스너를 제거합니다.
 * enabled 옵션을 통해 이벤트 리스너의 활성화 상태를 제어할 수 있습니다.
 *
 * @param element 이벤트를 등록할 DOM 요소 (Document 또는 HTMLElement)
 * @param event 등록할 이벤트 타입
 * @param callback 이벤트 발생 시 실행할 콜백 함수
 * @param options 이벤트 리스너 옵션 (capture, once, passive, signal)
 * @param enabled 이벤트 리스너 활성화 여부
 *
 * @example
 * useEventListener({
 *   element: document,
 *   event: 'click',
 *   callback: (e) => console.log('Document clicked', e),
 *   options: { passive: true },
 *   enabled: isActive,
 * });
 */
export function useEventListener<T extends keyof EventMap>({
  element,
  event,
  callback,
  options = {
    capture: false,
    once: false,
    passive: false,
    signal: undefined,
  },
  enabled = true,
}: UseEventListenerProps<T>) {
  const callbackRef = usePreserveCallback(callback);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    element.addEventListener(event, callbackRef as EventListener, {
      capture: options.capture,
      once: options.once,
      passive: options.passive,
      signal: options.signal,
    });
    return () => {
      element.removeEventListener(event, callbackRef as EventListener);
    };
  }, [
    event,
    callbackRef,
    element,
    options.capture,
    options.once,
    options.passive,
    options.signal,
    enabled,
  ]);
}
