import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';
import Link from 'next/link';

export const StyledLink = styled(Link)`
  padding: 6px 8px;
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${vars.neutralWhiteAlpha[8]};
  }
`;
