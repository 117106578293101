import { StackThemeProvider } from '@teamsparta/stack-core';
import { Flex, type FlexProps } from '@teamsparta/stack-flex';
import { vars } from '@teamsparta/stack-tokens';

import GNB from '@/components/gnb/GNB';

import * as S from './style';

interface Props extends FlexProps<'div'> {}

export function CommunityLayout({ style, children, ...props }: Props) {
  return (
    <Flex.Column
      fullWidth
      style={{
        backgroundColor: vars.neutral[100],
        minHeight: '100vh',
        ...style,
      }}
      padding='40px 0 0'
      {...props}
    >
      <GNB />
      <Flex
        fullWidth
        padding={{ mobile: '40px 0 0', desktop: '60px 0 167px' }}
        justify='center'
      >
        <S.ChildrenWrapper>
          <StackThemeProvider theme='hanghaeDark'>
            {children}
          </StackThemeProvider>
        </S.ChildrenWrapper>
      </Flex>
    </Flex.Column>
  );
}
