import 'styles/globals.css';
import 'styles/nprogress.css';

import { ErrorBoundary } from '@sentry/nextjs';
import { HydrationBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { vars } from '@teamsparta/stack-tokens';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import type { ReactElement, ReactNode } from 'react';

import { Meta } from '@/components/common/Meta';
import { StackDevtools } from '@/components/common/StackDevtools';
import Layout from '@/components/layout/Layout';
import { Providers } from '@/components/providers';
import { usePreviousPath } from '@/hooks/common/usePreviousPath';
import {
  ChannelScript,
  GoogleTagManagerBodyNoscript,
  GoogleTagManagerHeadScript,
  IamportScript,
  initAmplitude,
  initCPL,
  KakaoPageview,
} from '@/lib/sdk';
import { useSetDataDog } from '@/lib/sdk/dataDog';
import { env } from '@/lib/utils/env';
import ErrorPageTemplate from '@/pages/error/template';
import DesignSystemCss from '@/styles/DesignSystemCss';
import { fontFace } from '@/styles/fonts';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

initCPL();
initAmplitude();

NProgress.configure({
  template: '<div class="bar" role="bar"><div class="peg"></div></div>',
});

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  let { url } = router.query;

  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  usePreviousPath();
  useSetDataDog();

  return (
    <Providers>
      <Script
        id='kakao-js'
        defer={true}
        src='/js/kakao-dev.js'
        onLoad={() => {
          window.Kakao.init('535068688f1a8bca1c21a9445ede0a89');
        }}
      />
      <Meta
        title='항해99 - 성장의 벽을 동료들과 함께 뛰어넘는 개발자 학습 크루'
        description='성장에 대한 열망이 검증된 2000명 이상의 현직 개발자, 탑티어 시니어 코치진, 학습 후에도 이어지는 개발자 커뮤니티까지. 항해99에서 끊임없이 성장하는 개발자 크루에 합류하세요'
        url={`${env.NEXT_PUBLIC_PAGE_URL}`}
        robots='index, follow'
        googlebot='index, follow'
        yeti='index, follow'
        author='스파르타코딩클럽'
        type='website'
        favicon='/images/favicon/hanghae_favicon.ico'
        locale='ko_KR'
        keywords={[
          '부트캠프',
          '주니어 개발자',
          '웹개발',
          '프로그래밍',
          '개발자 이직',
          '백엔드',
          '프론트엔드',
          'AI',
          'LLM',
          '물경력 개발자',
          '개발자 멘토링',
          '코드 리뷰',
          '클린 코드',
          'Redis',
          '개발자 강의',
          '스터디',
        ]}
      />
      <Head>
        <link rel='icon' href='/favicon.ico' />
        <meta name='theme-color' content={vars.neutral[100]} />
        <GoogleTagManagerHeadScript />
        <IamportScript />
        <link
          rel='preconnect'
          href='https://developers.kakao.com'
          crossOrigin='true'
        />
        <link
          rel='preconnect'
          href='https://t1.daumcdn.net'
          crossOrigin='true'
        />
        <link
          rel='preconnect'
          href='https://cdn.channel.io'
          crossOrigin='true'
        />
        <style>{fontFace}</style>
        <meta
          name='viewport'
          content='user-scalable=no,width=device-width,initial-scale=1,shrink-to-fit=no'
        />
      </Head>
      <GoogleTagManagerBodyNoscript />
      {url && !JSON.parse(process.env.IS_DEV) && (
        <KakaoPageview url={url.toString()} />
      )}
      <ErrorBoundary
        fallback={(errorData) => (
          <ErrorPageTemplate statusCode={500} {...errorData} />
        )}
      >
        <HydrationBoundary state={pageProps.dehydratedState}>
          {getLayout(<Component {...pageProps} />)}
        </HydrationBoundary>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
      <StackDevtools />
      <ChannelScript />
      <DesignSystemCss />
    </Providers>
  );
}

export default MyApp;
