import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';

export const Dot = styled.span<{
  width?: number;
  height?: number;
  backgroundColor?: string;
}>`
  width: ${({ width }) => width ?? 2}px;
  height: ${({ height }) => height ?? 2}px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? vars.neutral[60]};
`;
