import { COURSE_LANDING_URL } from '@/constants';
import type { Course } from '@/types';

export type GNBDropdownMenu = {
  type: 'dropdown';
  name: string;
  items: GNBDropdownMenuItem[];
};

export type GNBDropdownMenuItem = {
  name: string;
  description: string;
  detailItems: {
    name: string;
    url: string;
    courseType: Course;
    newTab?: boolean;
  }[];
};

export type GNBLinkMenu = {
  type: 'link';
  name: string;
  url: string;
  newTab?: boolean;
};

/**
 * @todo 지금처럼 하드코딩으로 GNB_MENUS를 정의하는 것이 아니라, DB로부터 가져오는 것이 좋아보임. 언젠가는...
 */
export const GNB_MENUS: (GNBDropdownMenu | GNBLinkMenu)[] = [
  {
    name: '교육',
    type: 'dropdown',
    items: [
      {
        name: '현직자 부트캠프',
        description: '100명이 넘는 동료들과 필수 키워드 정복',
        detailItems: [
          {
            name: '항해 플러스 AI',
            courseType: '플러스 AI 코스',
            url: COURSE_LANDING_URL.HANGHAE_PLUS_AI,
          },
          {
            name: '항해 플러스 백엔드',
            courseType: '플러스 백엔드 코스',
            url: COURSE_LANDING_URL.HANGHAE_PLUS_BACKEND,
          },
          {
            name: '항해 플러스 프론트엔드',
            courseType: '플러스 프론트엔드 코스',
            url: COURSE_LANDING_URL.HANGHAE_PLUS_FRONTEND,
          },
        ],
      },
      {
        name: '단기 프로젝트',
        description: '1:1 코드 리뷰로 완성하는 4주 프로젝트',
        detailItems: [
          {
            name: 'Redis 단기 스킬업',
            courseType: '단기 스킬업 redis',
            url: COURSE_LANDING_URL.SHORT_TERM_SKILL_UP,
            newTab: true,
          },
        ],
      },
      {
        name: '강의 & 스터디',
        description: '국비지원으로, 이론부터 실전까지 정복',
        detailItems: [
          {
            name: 'Docker & CI/CD 스터디',
            courseType: 'Docker & CICD 강의 스터디',
            url: COURSE_LANDING_URL['99CLUB_DOCKER_STUDY'],
            newTab: true,
          },
          {
            name: 'Python 알고리즘 스터디',
            courseType: '알고리즘 강의 스터디',
            url: COURSE_LANDING_URL['99CLUB_ALGORITHM_STUDY'],
            newTab: true,
          },
          {
            name: 'Next.js 강의',
            courseType: 'Next.js 강의 스터디',
            url: COURSE_LANDING_URL['99CLUB_NEXT_STUDY'],
            newTab: true,
          },
          {
            name: 'Kubernetes 스터디',
            courseType: 'Kubernetes 강의 스터디',
            url: COURSE_LANDING_URL['99CLUB_KUBERNETES_STUDY'],
            newTab: true,
          },
          {
            name: '프론트 TDD & 성능최적화 강의',
            courseType: '프론트 TDD, CI/CD, 성능 최적화 강의 스터디',
            url: COURSE_LANDING_URL['99CLUB_TDD_STUDY'],
            newTab: true,
          },
        ],
      },
    ],
  },
  {
    name: '챌린지',
    type: 'dropdown',
    items: [
      {
        name: '데일리 인증',
        description: '1일 1인증으로 확실한 학습 습관 완성',
        detailItems: [
          {
            name: '1일 1알고리즘 챌린지',
            courseType: '99클럽 코딩테스트 스터디',
            url: COURSE_LANDING_URL['99CLUB_CODING_TEST'],
          },
          {
            name: '1일 1면접/아티클 챌린지',
            url: COURSE_LANDING_URL['INTERVIEW_ARTICLE_CHALLENGE'],
            courseType: '면접/아티클 챌린지',
          },
        ],
      },
    ],
  },
  {
    name: '커뮤니티',
    type: 'link',
    url: '/community',
  },
  {
    name: '후기&스토리',
    type: 'link',
    url: '/blog',
    newTab: true,
  },
];
