import { useEffect, useRef } from 'react';

const DEFAULT_EVENTS = ['touchstart', 'mousedown'];

/**
 *
 * @prop {() => void} [callback] - ref를 제외한 영역을 클릭할 시 실행될 callback 함수입니다.
 * @returns {React.MutableRefObject<T>} ref - 반환된 ref를 받은 컴포넌트를 제외한 영역을 클릭할 시 callback 함수가 실행됩니다.
 */
export default function useClickAway<T extends HTMLElement = HTMLElement>(
  callback: (event: Event) => void,
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const targetElement = ref.current;
    if (!targetElement) {
      return;
    }

    function handleCallback(event: Event) {
      if (!targetElement?.contains(event.target as Node)) {
        callback(event);
      }
    }

    DEFAULT_EVENTS.forEach((eventName) => {
      document.addEventListener(eventName, handleCallback);
    });

    return () => {
      DEFAULT_EVENTS.forEach((eventName) => {
        document.removeEventListener(eventName, handleCallback);
      });
    };
  }, [callback]);

  return ref;
}
