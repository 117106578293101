import { SwitchCase } from '@teamsparta/react';
import { Flex } from '@teamsparta/stack-flex';
import { useState } from 'react';

import type { GNBDropdownMenu, GNBLinkMenu } from '../../data';
import { GNB_MENUS } from '../../data';
import { DropdownMenu, LinkMenu } from './components';

export function DropdownMenus() {
  const [currentDropdownMenu, setCurrentDropdownMenu] = useState<string | null>(
    null,
  );

  function openDropdown(menu: string) {
    setCurrentDropdownMenu(menu);
  }

  function closeDropdown() {
    setCurrentDropdownMenu(null);
  }

  return (
    <Flex gap={8} align='center'>
      {GNB_MENUS.map((menu) => (
        <SwitchCase
          key={menu.name}
          value={menu.type}
          cases={{
            dropdown: (
              <DropdownMenu
                {...(menu as GNBDropdownMenu)}
                key={menu.name}
                opened={currentDropdownMenu === menu.name}
                open={() => openDropdown(menu.name)}
                close={closeDropdown}
              />
            ),
            link: <LinkMenu {...(menu as GNBLinkMenu)} />,
          }}
        />
      ))}
    </Flex>
  );
}
