import { queryOptions } from '@tanstack/react-query';

import { getRecentActiveOrientation } from '@/api/common/orientation';
import type { PlusCourse } from '@/types';

export const orientationQueryKeys = {
  all: ['orientation'],
  recentActiveOrientation: (courseName: PlusCourse) => [
    'orientation',
    courseName,
  ],
};

export const recentActiveOrientationQuery = (courseName: PlusCourse) =>
  queryOptions({
    queryKey: orientationQueryKeys.recentActiveOrientation(courseName),
    queryFn: () => getRecentActiveOrientation({ courseName }),
  });
