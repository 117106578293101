import { Flex } from '@teamsparta/stack-flex';

import { Skeleton } from '@/components/common/skeleton';

export function PollSkeleton() {
  return (
    <Flex.Column fullWidth gap={6}>
      <Skeleton style={{ width: '100%', height: '43px' }} />
      <Skeleton style={{ width: '100%', height: '43px' }} />
    </Flex.Column>
  );
}
