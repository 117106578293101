import * as Accordion from '@radix-ui/react-accordion';
import { Separated, When } from '@teamsparta/react';
import { Divider } from '@teamsparta/stack-divider';
import { Flex } from '@teamsparta/stack-flex';
import { ArrowChevronDown, ArrowChevronUp } from '@teamsparta/stack-icons';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useState } from 'react';

import { LoggingClick } from '@/components/common/LoggingClick';
import { Badge } from '@/components/new-gnb/components/Badge';
import type { GNBDropdownMenuItem } from '@/components/new-gnb/data';
import { SECOND } from '@/constants';

import * as S from './style';

interface Props {
  items: GNBDropdownMenuItem[];
}

const ANIMATION_DURATION = 0.125;
const DEBOUNCE_TIME = (ANIMATION_DURATION + 0.025) * SECOND;

export function InnerAccordion({ items }: Props) {
  /**
   * @todo 설명 주석 남기기
   */
  const isSingleItems = items.length === 1;
  const [values, setValues] = useState<string[]>(
    isSingleItems ? [items[0].name] : [],
  );
  const debouncedSetValues = debounce(setValues, DEBOUNCE_TIME);

  function hasValue(value: string) {
    return values.includes(value);
  }

  return (
    <Accordion.Root
      type='multiple'
      value={values}
      onValueChange={debouncedSetValues}
      disabled={isSingleItems}
    >
      <Flex.Column
        padding='24px 20px'
        gap={20}
        fullWidth
        style={{ background: 'rgba(0, 0, 0, 0.16)' }}
      >
        <Separated with={<Divider color={vars.neutral[90]} />}>
          {items.map((item) => (
            <Accordion.Item
              key={item.name}
              value={item.name}
              style={{ width: '100%' }}
            >
              <LoggingClick
                logName='hh_gnb_click'
                data={{ button_text: item.name }}
              >
                <Accordion.Trigger
                  style={{ width: '100%' }}
                  disabled={isSingleItems}
                >
                  <Flex align='center' fullWidth justify='between'>
                    <Text
                      as='span'
                      font='bodyCompact'
                      color={vars.text.secondary}
                    >
                      {item.name}
                    </Text>
                    <AnimatePresence mode='popLayout'>
                      <motion.span
                        key={hasValue(item.name) ? 'up' : 'down'}
                        initial={{ opacity: 0.3 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0.3 }}
                        transition={{ duration: ANIMATION_DURATION }}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <When condition={!isSingleItems}>
                          <When
                            condition={hasValue(item.name)}
                            fallback={
                              <ArrowChevronDown
                                size={20}
                                color={vars.icon.primary}
                              />
                            }
                          >
                            <ArrowChevronUp
                              size={20}
                              color={vars.icon.primary}
                            />
                          </When>
                        </When>
                      </motion.span>
                    </AnimatePresence>
                  </Flex>
                </Accordion.Trigger>
              </LoggingClick>
              <S.StyledAccordionContent>
                <Flex.Column gap={12}>
                  <Text as='p' font='tag1M' color={vars.text.quaternary}>
                    {item.description}
                  </Text>
                  {item.detailItems.map((detailItem) => (
                    <LoggingClick
                      key={detailItem.name}
                      logName='hh_gnb_click'
                      data={{ button_text: detailItem.name }}
                    >
                      <Link href={detailItem.url} style={{ width: '100%' }}>
                        <Flex
                          gap={6}
                          padding={16}
                          fullWidth
                          style={{
                            borderRadius: 8,
                            backgroundColor: 'rgba(0, 0, 0, 0.50)',
                          }}
                        >
                          <Text
                            as='span'
                            font='captionSb'
                            color={vars.text.secondary}
                          >
                            {detailItem.name}
                          </Text>
                          <Badge courseType={detailItem.courseType} />
                        </Flex>
                      </Link>
                    </LoggingClick>
                  ))}
                </Flex.Column>
              </S.StyledAccordionContent>
            </Accordion.Item>
          ))}
        </Separated>
      </Flex.Column>
    </Accordion.Root>
  );
}

/**
 * @todo refactor: 따로 분리하기
 */
function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<T>) => {
    // 이전 타이머가 있으면 제거
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    // 새 타이머 설정
    timeout = setTimeout(() => {
      func(...args);
      timeout = null;
    }, wait);
  };

  // 타이머 취소 메서드 추가
  debounced.cancel = () => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return debounced;
}
