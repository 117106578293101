import styled from '@emotion/styled';
import { vars } from '@teamsparta/stack-tokens';
import { motion } from 'framer-motion';
import Link from 'next/link';

export const DropdownMenuTrigger = styled.button<{ opened?: boolean }>`
  padding: 6px 8px;
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 3px;
  background-color: ${({ opened }) =>
    opened ? vars.neutralWhiteAlpha[8] : 'transparent'};

  &:hover {
    background-color: ${vars.neutralWhiteAlpha[8]};
  }
`;

export const DropdownMenuContent = styled(motion.div)`
  position: fixed;
  top: var(--desktop-gnb-height);
  left: 0;
  width: 100%;
  background: rgba(29, 32, 33, 0.64);
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  padding: 32px 0 40px;
  z-index: 20;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 24px;
  border-radius: 8px;
  background-color: #00000080;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: rgba(14, 15, 17, 0.5);
  }
`;
