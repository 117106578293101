import { useLoadableVariationDetail } from '@hackler/react-sdk';
import { ArrowDropDownLine } from '@teamsparta/design-system';
import { SwitchCase, When } from '@teamsparta/react';
import { Flex } from '@teamsparta/stack-flex';
import { Text } from '@teamsparta/stack-text';
import { vars } from '@teamsparta/stack-tokens';
import { useAtom, useSetAtom } from 'jotai';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useRef, useState } from 'react';

import logo from '/public/images/logo/logo.png';
import { LoggingClick } from '@/components/common/LoggingClick';
import MobileGnb from '@/components/gnb/MobileGNB';
import { GNB } from '@/components/new-gnb';
import { SHORT_TERM_SKILL_UP } from '@/constants';
import type {
  GnbMenuGroup,
  GnbMenuItem,
  GnbMenuSubGroup,
  GnbRootMenuGroup,
} from '@/constants/gnb';
import {
  BADGE_TEXT_MAP,
  GnbMenuList,
  isGroup,
  isSubGroup,
} from '@/constants/gnb';
import { useClickAway, useOnlineUser } from '@/hooks';
import { useRecruitingStatusByCourse } from '@/hooks/react-query/common/round-status';
import { useScrollDirection } from '@/lib/utils/businessLogics/_common/useScrollDirection';
import { useWindowScrollY } from '@/lib/utils/businessLogics/util-browser';
import { isClient } from '@/lib/utils/helpers/enviroment';
import { isAlreadyPopupAtom, MobileGNBOpenAtom } from '@/store/landing';
import { useLoginModal } from '@/store/login';

import { getCourseBadgeText, isDisabledCourse } from '../logic';
import UserGnbButton from './components/UserGnbButton';
import { getIsActivatedGroup } from './logic';
import * as S from './style';

interface GnbAProps {
  fixed?: boolean;
}

export default function Gnb({ fixed = false }: GnbAProps) {
  const { decision, isLoading } = useLoadableVariationDetail(419);

  return (
    <When condition={!isLoading}>
      <SwitchCase
        value={decision.variation as 'A' | 'B'}
        cases={{
          A: <GnbA fixed={fixed} />,
          B: <GNB />,
        }}
      />
    </When>
  );
}

function GnbA({ fixed = false }: GnbAProps) {
  const router = useRouter();
  const setIsAlreadyPopup = useSetAtom(isAlreadyPopupAtom);
  const [isOpen, setIsOpen] = useAtom(MobileGNBOpenAtom);
  const userInfo = useOnlineUser();
  const loginModal = useLoginModal();
  const scrollY = useWindowScrollY();
  const direction = useScrollDirection();

  const isSignIn = !!userInfo;

  function handleClickLogin() {
    if (isSignIn) {
      return;
    }
    loginModal.open(router.asPath);
  }

  useEffect(() => {
    function handleRouteChange() {
      setIsOpen(false);
      setIsAlreadyPopup(false);
    }
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, setIsAlreadyPopup, setIsOpen]);

  const rootGroups = GnbMenuList.filter(({ type }) => type === 'rootGroup');
  const items = GnbMenuList.filter(({ type }) => type === 'item');
  const [currentRootGroupTitle, setCurrentRootGroupTitle] = useState<
    string | null
  >(null);
  function handleClickRootGroupTrigger(title: string) {
    setCurrentRootGroupTitle((previous) => (previous === title ? null : title));
  }
  function handleCloseRootGroup() {
    setCurrentRootGroupTitle(null);
  }

  return (
    <S.Wrapper fixed={fixed} scrollY={scrollY} scrollDirection={direction}>
      <S.Container scrollY={scrollY} isMobileGnbOpen={isOpen}>
        <S.SubWrapper fixed={fixed} scrollDirection={direction}>
          <S.ImageAndNavBtn fixed={fixed} scrollDirection={direction}>
            <Link href='/' passHref>
              <S.ImageWrapper>
                <Image
                  src={logo}
                  layout='fill'
                  objectFit='contain'
                  alt='logo'
                />
              </S.ImageWrapper>
            </Link>

            <S.PageBtnList>
              <S.PageBtnEachSide>
                {rootGroups.map((menu) => (
                  <GnbRootGroup
                    key={menu.title}
                    data={menu as GnbRootMenuGroup}
                    opened={currentRootGroupTitle === menu.title}
                    close={handleCloseRootGroup}
                    onClickTrigger={handleClickRootGroupTrigger}
                  />
                ))}
                {items.map((menu) => (
                  <GnbListLinkItem
                    key={menu.title}
                    data={menu as GnbMenuItem}
                  />
                ))}
              </S.PageBtnEachSide>

              <S.PageBtnEachSide>
                {userInfo?._id ? (
                  <UserGnbButton />
                ) : (
                  <S.LogoutBtn
                    scrollDirection={fixed ? 'up' : direction}
                    onClick={handleClickLogin}
                  >
                    로그인
                  </S.LogoutBtn>
                )}
              </S.PageBtnEachSide>
            </S.PageBtnList>
          </S.ImageAndNavBtn>
        </S.SubWrapper>
        <MobileGnb />
      </S.Container>
    </S.Wrapper>
  );
}

interface GnbRootGroupProps {
  data: GnbRootMenuGroup;
  opened: boolean;
  close: () => void;
  onClickTrigger: (title: string) => void;
}

function GnbRootGroup({
  data,
  opened,
  close,
  onClickTrigger,
}: GnbRootGroupProps) {
  const { title, children } = data;
  const router = useRouter();

  const isActiveGroup = getIsActivatedGroup(
    children ?? [],
    (item) => item.link === router.asPath,
  );

  const triggerRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useClickAway<HTMLDivElement>((event) => {
    if (!opened) {
      return;
    }

    if (triggerRef.current?.contains(event.target as Node)) {
      return;
    }

    close();
  });
  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef]);

  return (
    <S.GnbRootGroupContainer>
      <LoggingClick logName='hh_gnb_click' data={{ button_text: title }}>
        <S.StyledLink
          ref={triggerRef}
          isClicked={opened || isActiveGroup}
          onClick={() => onClickTrigger(title)}
        >
          {title}
          <S.ArrowDropDownLineWrapper open={opened}>
            <ArrowDropDownLine size={16} color='#B4BFC6' />
          </S.ArrowDropDownLineWrapper>
        </S.StyledLink>
      </LoggingClick>
      <S.GnbGroupExpandOverlay isOpen={opened} />
      <S.GnbGroupExpandOverlayContentWrapper
        isOpen={opened}
        contentHeight={contentHeight}
        ref={contentRef}
      >
        <S.GnbGroupExpandOverlayContent>
          {children?.map(
            (menu) =>
              isGroup(menu) && (
                <Fragment key={menu.title}>
                  <GnbGroup data={menu} />
                </Fragment>
              ),
          )}
        </S.GnbGroupExpandOverlayContent>
      </S.GnbGroupExpandOverlayContentWrapper>
    </S.GnbRootGroupContainer>
  );
}

interface GnbGroupProps {
  data: GnbMenuGroup;
}

function GnbGroup({ data }: GnbGroupProps) {
  const { children } = data;

  return (
    <S.GnbGroupContainer>
      {children.map(
        (submenu) =>
          isSubGroup(submenu) && (
            <GnbSubGroup key={submenu.title} {...submenu} />
          ),
      )}
    </S.GnbGroupContainer>
  );
}

interface GnbListLinkProps {
  data: GnbMenuItem;
}

function GnbListLinkItem({ data }: GnbListLinkProps) {
  const { link, title, newTab, isNew } = data;
  const router = useRouter();

  const isActive = router.asPath.includes(link);

  return (
    <LoggingClick
      logName='hh_gnb_click'
      data={{
        button_text: title,
        button_href: link,
        page_title: isClient() ? document.title : '',
      }}
    >
      <S.PageBtnListItem>
        <Link href={link} target={newTab ? '_blank' : ''} passHref>
          <S.StyledLink isClicked={isActive}>{title}</S.StyledLink>
        </Link>
        {isNew && <S.RedDot />}
      </S.PageBtnListItem>
    </LoggingClick>
  );
}

interface GnbSubGroupProps extends GnbMenuSubGroup {}

function GnbSubGroup({ title, menus }: GnbSubGroupProps) {
  return (
    <Flex.Column gap={12}>
      {title && (
        <Text
          as='span'
          color={vars.neutral[50]}
          font={{
            mobile: 'captionM',
            desktop: 'captionM',
          }}
        >
          {title}
        </Text>
      )}
      {menus.map((item) => (
        <GnbSubGroupItem key={item.title} {...item} />
      ))}
    </Flex.Column>
  );
}

interface GnbSubGroupItemProps extends GnbMenuItem {}

function GnbSubGroupItem({
  link,
  title,
  courseType,
  newTab,
  disabled,
}: GnbSubGroupItemProps) {
  const router = useRouter();
  const { status } = useRecruitingStatusByCourse({
    course: courseType,
    enabled: !isDisabledCourse(courseType),
  });
  const isActive = router.asPath.includes(link);

  const badgeText = getCourseBadgeText(courseType, BADGE_TEXT_MAP[status]);

  const getButtonText = () => {
    if (courseType === SHORT_TERM_SKILL_UP) {
      return '단기 스킬업';
    }
    return title;
  };

  return (
    <LoggingClick
      logName='hh_gnb_click'
      data={{
        button_text: getButtonText(),
        button_href: link,
        page_title: isClient() ? document.title : '',
      }}
    >
      <Flex.Row align='center' gap={8}>
        <Link
          key={title}
          href={disabled ? '' : link}
          target={newTab ? '_blank' : ''}
          passHref
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}
        >
          {title && (
            <S.GnbSubGroupItem
              as='span'
              font={{
                mobile: 'bodyM',
                desktop: isActive ? 'bodyB' : 'bodyM',
              }}
              color={isActive ? vars.brand.default : vars.neutral[0]}
              isActive={isActive}
              disabled={disabled}
            >
              {title}
            </S.GnbSubGroupItem>
          )}
        </Link>
        <S.RoundInfoBadge isNew badgeText={badgeText}>
          {badgeText}
        </S.RoundInfoBadge>
      </Flex.Row>
    </LoggingClick>
  );
}
