import { ContentsNote, NavigationSetting } from '@teamsparta/stack-icons';
import { vars } from '@teamsparta/stack-tokens';
import Image from 'next/image';

export const MY_PAGES = [
  {
    name: '나의 수강',
    url: '/users/my-page?tab=나의 수강',
    icon: (
      <Image src='/images/icons/waveIcon.svg' alt='' width={16} height={16} />
    ),
  },
  {
    name: '결제 내역',
    url: '/users/my-page?tab=결제 내역',
    icon: <ContentsNote size={16} style='line' color={vars.neutral[10]} />,
  },
  {
    name: '계정 정보',
    url: '/users/my-page?tab=계정 정보',
    icon: <NavigationSetting size={16} style='line' color={vars.neutral[10]} />,
  },
];
