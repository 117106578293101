import { useEffect } from 'react';

type UseScrollToTriggerModalProps = {
  scrollY: number;
  reachingDiv?: number;
  isAlreadyPopup: boolean;
  setModal: (value: boolean) => void;
  setIsAlreadyPopup: (value: boolean) => void;
  showModal?: boolean;
};

function useScrollToTriggerModal({
  scrollY,
  reachingDiv,
  isAlreadyPopup,
  setModal,
  setIsAlreadyPopup,
  showModal = true,
}: UseScrollToTriggerModalProps) {
  useEffect(() => {
    const innerHeight = window.innerHeight;
    if (!reachingDiv || isAlreadyPopup || !showModal) {
      return;
    }
    if (!isAlreadyPopup && scrollY + innerHeight >= reachingDiv) {
      setModal(true);
      setIsAlreadyPopup(true);
    }
  }, [scrollY, reachingDiv]);
}

export default useScrollToTriggerModal;
